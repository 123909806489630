import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { StateContext, DispatchContext } from '../../../core/contexts';
import { UserType } from '../../../domains/user/user.model';
import { updateIsLoggedIn } from '../../../core/reducer';
import * as Constants from '../../../core/constants';

export const useMapContainerService = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const isBrowseMode = useRef(false);
  useEffect(() => {
    if (state.user.type === UserType.Browse) {
      if (!state.isLoggedIn) {
        dispatch(updateIsLoggedIn(true));
      }
      isBrowseMode.current = true;
    } else {
      isBrowseMode.current = false;
    }
    // eslint-disable-next-line
  }, [state.user.type]);

  const isShareMode = useRef<boolean>(state.isSharing);
  useEffect(() => {
    isShareMode.current = state.isSharing;
  }, [state.isSharing]);

  const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false);
  const openAboutDialog = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'About',
    });
    setIsAboutDialogOpen(true);
  }, []);

  const [isUserListOpen, setIsUserListOpen] = useState(false);

  const [isInfomationDialogOpen, setIsInfomationDialogOpen] = useState(false);
  const openInfomationDialog = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Infomation',
    });
    setIsInfomationDialogOpen(true);
  }, []);

  const [isSharePopperOpen, setIsSharePopperOpen] = useState(false);
  const [
    sharePopperAnchor,
    setSharePopperAnchor,
  ] = useState<HTMLButtonElement | null>(null);
  const setSharePopperOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (sharePopperAnchor === null) {
      setSharePopperAnchor(event.currentTarget);
    }
    setIsSharePopperOpen(!isSharePopperOpen);
  };

  const [locationUrl, setLocationUrl] = useState('');
  const setUrl = (pathname: string) => {
    setLocationUrl(
      encodeURIComponent(
        process.env.REACT_APP_SITE_URL +
          (state.user.type === UserType.Guide
            ? pathname.replace('/' + state.user.token.toString(), '')
            : pathname),
      ),
    );
  };

  const getShareUrl = (snsName: 'Twitter' | 'Facebook' | 'LINE') => {
    switch (snsName) {
      case 'Twitter':
        const shareText = encodeURIComponent(
          `${Constants.Service} ${Constants.Title}`,
        );
        return `https://twitter.com/share?text=${shareText}&url=${locationUrl}`;
      case 'Facebook':
        return `https://www.facebook.com/sharer.php?u=${locationUrl}`;
      case 'LINE':
        return `https://social-plugins.line.me/lineit/share?url=${locationUrl}`;
    }
  };

  const getIsMobileDisplay = useCallback(() => {
    return window.matchMedia('(max-width: 959px)').matches;
  }, []);

  const [isMobileDisplay, setIsMobileDisplay] = useState(false);
  const handleResize = useCallback(() => {
    setIsMobileDisplay(getIsMobileDisplay());
  }, [getIsMobileDisplay]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    setIsMobileDisplay(getIsMobileDisplay());
    // eslint-disable-next-line
  }, []);

  return {
    state,
    isBrowseMode,
    isShareMode,
    isAboutDialogOpen,
    isUserListOpen,
    isInfomationDialogOpen,
    isSharePopperOpen,
    sharePopperAnchor,
    isMobileDisplay,
    dispatch,
    setIsAboutDialogOpen,
    setIsUserListOpen,
    setIsInfomationDialogOpen,
    openAboutDialog,
    openInfomationDialog,
    setIsSharePopperOpen,
    setSharePopperOption,
    setUrl,
    getShareUrl,
    handleResize,
  };
};
