import { useRef } from 'react';

export type WebSocketRepository = {
  get(): WebSocket | undefined;
  connect(): void;
  disconnect(): void;
  clearHandler(): void;
  send<Message>(data: Message): void;
};

export const useWebSocketRepository = (
  room: string,
  callback: {
    handleWebSocketOpen(): any;
    handleWebSocketClose(event: CloseEvent): any;
    handleWebSocketError(event: Event): any;
    handleWebSocketMessage(event: MessageEvent): any;
  },
): WebSocketRepository => {
  const webSocket = useRef<WebSocket>();
  const createWebSocketPath = (room: string) =>
    `${process.env.REACT_APP_WEBSOCKET_SERVER}?room=${room}`;

  const createWebSocket = (): WebSocket => {
    if (webSocket.current?.readyState === WebSocket.OPEN) {
      return webSocket.current;
    }

    const webSocketPath = createWebSocketPath(room);
    webSocket.current = new WebSocket(webSocketPath);
    return webSocket.current;
  };

  const get = () => {
    return webSocket.current;
  };

  const connect = () => {
    if (webSocket.current) {
      disconnect();
    }

    clearHandler();
    webSocket.current = createWebSocket();
    webSocket.current.onopen = callback.handleWebSocketOpen;
    webSocket.current.onclose = callback.handleWebSocketClose;
    webSocket.current.onerror = callback.handleWebSocketError;
    webSocket.current.onmessage = callback.handleWebSocketMessage;
  };

  const clearHandler = () => {
    if (!webSocket.current) {
      return;
    }

    webSocket.current.onopen = null;
    webSocket.current.onclose = null;
    webSocket.current.onerror = null;
    webSocket.current.onmessage = null;
    webSocket.current = undefined;
  };

  const disconnect = () => {
    if (
      webSocket.current?.readyState !== WebSocket.OPEN ||
      !webSocket.current
    ) {
      return;
    }

    if (webSocket.current?.bufferedAmount === 0) {
      webSocket.current?.close(1000);
    } else {
      setTimeout(() => {
        disconnect();
      }, 2000);
    }
  };

  const send = <Message>(data: Message) => {
    if (!webSocket.current) {
      disconnect();
      return;
    }

    if (webSocket.current?.readyState === WebSocket.OPEN) {
      try {
        webSocket.current.send(JSON.stringify(data));
      } catch {
        throw new Error('WebSocket send error');
      }
    } else {
      disconnect();
    }
  };

  return {
    get,
    connect,
    disconnect,
    clearHandler,
    send,
  };
};
