import React from 'react';

type Props = {
  isDisplay: boolean;
  options?: {
    style: { [key: string]: React.CSSProperties };
  };
  children?: React.ReactNode;
};

const Overlay = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    overlay: {
      display: props.isDisplay ? 'flex' : 'none',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1200,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      ...props.options?.style,
    },
  };

  return <div style={style.overlay}>{props.children}</div>;
});

export default Overlay;
