import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { UserType } from '../../../../domains/user/user.model';

type Props = {
  intl: IntlShape;
  name: string;
  userType: UserType;
  isVtour: boolean;
  isError: boolean;
  handleChange: (value: string) => void;
};

const ConnectionName = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginTop:
        props.isVtour && props.userType !== UserType.Guide ? '24px' : '0',
      marginBottom: props.userType === UserType.Guide ? '0' : '24px',
    },
    iconWrapper: {
      margin: '0 auto 8px',
      width: '32px',
      height: '32px',
    },
    wrapper: {
      display: props.userType === UserType.Guide ? 'block' : 'inline-flex',
      alignItems: props.userType === UserType.Guide ? 'unset' : 'center',
      position: 'relative',
    },
    label: {
      color: props.userType === UserType.Guide ? '#fff' : '#666',
      fontSize: '12px',
      fontWeight: 'bold',
      width: props.userType === UserType.Guide ? 'unset' : '88px',
      flexBasis: props.userType === UserType.Guide ? 'unset' : '88px',
      display: 'inline-block',
      margin: 0,
    },
    required: {
      fontSize: '10px',
      color: '#e53935',
    },
    input: {
      color: '#424242',
      margin: '0 0 0 8px',
      padding: '0 8px',
      width: '152px',
      border: '1px solid #dbdbdb',
      height: '32px',
      fontSize: '16px',
    },
    error: {
      display: 'block',
      marginTop: '8px',
      height: '24px',
      color: '#fe6700',
      fontSize: '10px',
      fontWeight: 700,
    },
  };

  const i18nId =
    props.userType === UserType.Guide
      ? 'ConnectionName.Guidename'
      : 'ConnectionName.Nickname';

  const placeholder = props.intl.formatMessage({
    id:
      props.userType === UserType.Guide ? i18nId : 'ConnectionName.Placeholder',
  });

  return (
    <div style={style.div}>
      <div style={style.wrapper}>
        <label style={style.label}>
          <span style={style.required}>＊</span>
          <FormattedMessage id={i18nId} />
        </label>
        <input
          type="text"
          autoCapitalize="off"
          maxLength={16}
          placeholder={placeholder}
          value={props.name}
          onChange={(event) => props.handleChange(event.currentTarget.value)}
          style={style.input}
        />
      </div>
      <label style={style.error}>
        {props.isError && <FormattedMessage id="ConnectionName.Error" />}
      </label>
      {props.userType !== UserType.Guide && (
        <div style={style.iconWrapper}>
          {props.name !== '' && (
            <img
              src={`/images/characters/${props.name.substr(0, 1)}.svg`}
              alt="icon"
              width="32"
              height="32"
            />
          )}
        </div>
      )}
    </div>
  );
});

export default injectIntl(ConnectionName);
