import React, { memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { ja, en } from '../../../core/i18n/vmap';
import { useLang } from '../../../hooks/useLang';
import { useVmapService } from '../hooks/useVmapService';
import { MapService } from '../../../domains/map';

import ConnectionContainer from '../../connection/ConnectionContainer';
import MapContainer from './MapContainer';
import Html from '../../../core/components/Html';

interface Props extends RouteComponentProps {}

const VmapContainer = memo((props: Props) => {
  const vmapService = useVmapService(props);
  const lang = useLang({ ja, en });

  const Vmap =
    vmapService.mapID &&
    vmapService.protocol &&
    vmapService.state?.user.token ? (
      <>
        {!vmapService.state.isLoggedIn && (
          <ConnectionContainer
            history={props.history}
            vtourOptions={vmapService.vtourOptions}
            name={vmapService.name}
            character={vmapService.character}
            protocol={vmapService.protocol}
            handleUserTypeSelected={vmapService.handleUserTypeSelected}
            handleNameChange={vmapService.handleNameChange}
          />
        )}
        <MapContainer
          history={props.history}
          mapService={vmapService.mapService as MapService}
          vtourOptions={vmapService.vtourOptions}
          mapID={vmapService.mapID}
          character={vmapService.character}
          protocol={vmapService.protocol}
        />
      </>
    ) : null;

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <Html
        metaData={vmapService.state?.metaData}
        isVtour={vmapService.vtourOptions?.isVtour}
      />
      {Vmap}
    </IntlProvider>
  );
});

export default VmapContainer;
