import React from 'react';
import { FormattedMessage } from 'react-intl';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  vtourUrl: string;
  token: number | undefined;
  isGuide: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  handleUrlCopy: (target: string) => void;
};

const VtourUrlText = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    span: {
      fontSize: '10px',
      margin: '0 0 16px',
      whiteSpace: 'nowrap',
    },
    urlWrapper: {
      position: 'relative',
      marginLeft: '8px',
      width: '232px',
      textAlign: 'left',
    },
    input: {
      margin: '0 auto 8px',
      padding: '8px',
      width: '172px',
      height: '22px',
      color: '#424242',
      fontSize: '16px',
      overflowY: 'hidden',
      border: '1px solid #424242',
      backgroundColor: '#fff',
      whiteSpace: 'nowrap',
    },
  };

  const urlCopy = css({
    position: 'absolute',
    top: 0,
    right: '-5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    width: '48px',
    height: '40px',
    backgroundColor: '#cacaca',
    fontSize: '8px',
    color: '#333',
    border: '1px solid #000',
    borderRadius: '2px',
    ':disabled': {
      backgroundColor: '#ddd',
      color: '#666',
      borderColor: '#333',
      cursor: 'not-allowed',
    },
  });

  const messageId = props.isGuide
    ? 'VtourUrl.UrlForGuide'
    : 'VtourUrl.UrlForUser';

  const target = props.vtourUrl ? (props.isGuide ? 'guide' : 'user') : '';

  const vtourUrl = props.vtourUrl
    ? `${process.env.REACT_APP_SITE_URL}${props.vtourUrl}${
        props.isGuide ? `/${props.token}` : ''
      }`
    : '';

  return (
    <div style={style.flex}>
      <span style={style.span}>
        <FormattedMessage id={messageId} />
      </span>
      <div style={style.urlWrapper}>
        <textarea
          ref={props.textareaRef}
          style={style.input}
          value={vtourUrl}
          readOnly={true}
        />
        <button
          css={urlCopy}
          onClick={() => props.handleUrlCopy(target)}
          disabled={!props.vtourUrl}>
          <img src="/images/icons/copy.svg" alt="copy" width="24" height="25" />
        </button>
      </div>
    </div>
  );
});

export default VtourUrlText;
