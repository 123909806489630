import React from 'react';

type Props = {
  openAboutDialog: () => void;
};

const AboutButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    about: {
      position: 'absolute',
      top: '16px',
      left: '16px',
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'block',
      border: 0,
      backgroundColor: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
      width: '48px',
      height: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
  };

  return (
    <button onClick={props.openAboutDialog} style={style.about}>
      <img src="/images/icons/about.svg" alt="about" width="16" height="20" />
    </button>
  );
});

export default AboutButton;
