import React, { memo, useLayoutEffect, useRef } from 'react';

type Props = {
  handleInit: (mapRef: HTMLElement) => void;
  children?: React.ReactNode;
};

const StrolyMap = memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      overflow: 'hidden',
    },
    map: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
    },
  };

  const mapRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (mapRef.current) {
      props.handleInit(mapRef.current);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={style.div}>
      <div ref={mapRef} style={style.map}></div>
      {props.children}
    </div>
  );
});

export default StrolyMap;
