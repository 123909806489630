import { LatLng } from '../domains/map/map.model';
import { Character } from '../domains/user/character';
import { WebSocketRepository } from '../repositories/useWebSocketRepository';
import { LocationMessage, CommentMessage } from '../domains/ws/message.model';
import { ActionType } from '../domains/ws/message.model';

export type WebSocketSendService = {
  sendAvatarUserRemove: (isLeaving: boolean) => void;
  sendComment: (sendData: {
    id: string;
    date: string;
    latlng: LatLng;
    comment: string;
    type: 'landmark' | 'comment' | 'emoji';
  }) => void;
};

export const useWebSocketSendService = (
  webSocketRepository: WebSocketRepository,
  room: string,
  user: {
    token: number;
    color: string;
    character: Character;
  },
) => {
  const UserConfig = {
    room: room as string,
    token: user.token,
    color: user.color,
    name: user.character.getName(),
    userType: user.character.getUserType(),
  } as const;

  const sendAvatarUserRemove = (isLeaving: boolean): void => {
    const task = isLeaving ? 'removeCentralMarker' : 'hideCentralMarker';
    const locationMassage: LocationMessage = {
      ...UserConfig,
      id: new Date().getTime(),
      lat: NaN,
      lng: NaN,
      task,
      action: ActionType.Location,
    };
    webSocketRepository.send<LocationMessage>(locationMassage);
  };

  const sendComment = (sendData: {
    id: string;
    date: string;
    latlng: LatLng;
    comment: string;
    type: 'landmark' | 'comment' | 'emoji';
  }): void => {
    const commentMessage: CommentMessage = {
      ...UserConfig,
      id: sendData.id,
      date: sendData.date,
      latlng: sendData.latlng,
      comment: sendData.comment,
      type: sendData.type,
      action: ActionType.Sendmessage,
    };
    webSocketRepository.send<CommentMessage>(commentMessage);
  };

  return {
    sendAvatarUserRemove,
    sendComment,
  };
};
