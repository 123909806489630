import React from 'react';
import Badge from '@material-ui/core/Badge';

/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  unReaded: number;
  handleButtonClick: () => void;
};

const CommentOpener = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      width: '48px',
      height: '48px',
      boxSizing: 'border-box',
      textAlign: 'center',
      padding: 0,
      filter: 'drop-shadow(0px 0 4px #333)',
      border: 0,
      borderRadius: '50%',
      backgroundColor: '#fff',
      backgroundImage: 'url(/images/icons/comment.svg)',
      backgroundPosition: 'center center',
      backgroundSize: '23px 18px',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
  };

  const opener = css({
    ':focus': {
      outline: 'none',
    },
  });

  const badge = css({
    position: 'absolute',
    top: '16px',
    right: '8px',
    '.MuiBadge-anchorOriginTopRightRectangle': {
      top: '8px',
      right: '8px',
    },
    '@media(max-width: 959px)': {
      top: 'unset',
      bottom: '8px',
    },
  });

  return (
    <Badge color="secondary" css={badge} badgeContent={props.unReaded}>
      <button
        style={style.button}
        css={opener}
        onClick={props.handleButtonClick}></button>
    </Badge>
  );
});

export default CommentOpener;
