import React, { useCallback } from 'react';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  type?: 'image' | 'text';
  isCopyright?: boolean;
  color?: string;
  position?: 'default' | 'responsive';
};

const Address = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    address: {
      color: props.color || '#696969',
      fontWeight: 'bold',
      fontSize: '14px',
      fontStyle: 'normal',
    },
    a: {
      verticalAlign: props.type === 'text' ? 'baseline' : '-11px',
      color: props.color || '#696969',
    },
    img: {
      filter: 'drop-shadow(0px 0px 1px #000)',
      marginLeft: '4px',
    },
  };

  const handleLinkClick = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Stroly',
    });
  }, []);

  const year = new Date().getFullYear();
  const prefix = props.isCopyright ? <>&copy; {year} </> : <>Powered by </>;
  const stroly =
    props.type === 'text' ? (
      'Stroly Inc.'
    ) : (
      <img
        src="/images/stroly.svg"
        alt="Stroly Inc."
        width="56"
        height="34"
        style={style.img}
      />
    );

  const responsive = css({
    textShadow: '0 0 2px #424242',
    position: 'absolute',
    right: '56px',
    bottom: '4px',
    color: '#fff',
    '@media(max-width: 959px)': {
      right: 'unset',
      left: 'calc(50% - 71px)',
    },
  });

  return (
    <address
      style={style.address}
      css={props.position === 'responsive' && responsive}>
      {prefix}
      <a
        style={style.a}
        href="https://stroly.com/"
        onClick={handleLinkClick}
        target="_blank"
        rel="noopener noreferrer">
        {stroly}
      </a>
    </address>
  );
};

export default Address;
