import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  limit: number;
  handleSelectChange: React.Dispatch<React.SetStateAction<number>>;
};

const VtourForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      margin: '8px 0 16px 8px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      margin: '0',
      width: '104px',
      fontWeight: 500,
      fontSize: '12px',
      textAlign: 'left',
    },
    select: {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      padding: '0 8px',
      width: '72px',
      height: '32px',
      color: '#424242',
      fontSize: '16px',
      background: `
      url(/images/icons/arrow_select.svg) no-repeat right 8px center,
      linear-gradient(
        90deg,#fff 0%,
        #fff 45px,
        #efefef 45px,
        #efefef 100%
        )`,
      border: '1px solid #424242',
      borderRadius: '2px',
    },
    afterSelect: {
      paddingLeft: '8px',
      fontWeight: 500,
      fontSize: '12px',
    },
  };

  const option = Array.from(new Array(30)).map((_, index) => {
    const value = index + 1;
    return (
      <option value={value} key={value}>
        {value}
      </option>
    );
  });

  return (
    <div style={style.div}>
      <label style={style.label}>
        <p style={style.text}>
          <FormattedMessage id="VtourForm.Participants" />
        </p>
        <FormattedMessage id="VtourForm.UpToEN" />{' '}
        <select
          value={props.limit}
          onChange={(event) =>
            props.handleSelectChange(Number(event.currentTarget.value))
          }
          style={style.select}>
          {option}
        </select>{' '}
        <span style={style.afterSelect}>
          <FormattedMessage id="VtourForm.UpToJA" />
        </span>
      </label>
    </div>
  );
});

export default VtourForm;
