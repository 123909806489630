export const ja = {
  'ConnectionName.Nickname': 'nickname',
  'ConnectionName.Guidename': 'ガイド名',
  'ConnectionName.Placeholder': '英字小文字のみ',
  'ConnectionName.Error': '英字小文字でニックネームを入れてください。',
  'ConnectionStartDate.DateAndTime': 'ツアー開始時間',
  'ConnectionStartDate.StartDateTime': '{year}年{month}月{day}日 {time}',
  'ConnectionBeforeTour.DateAndTime': 'ツアー開始時間',
  'ConnectionBeforeTour.Message': '開始5分前から入場できます。',
  'ConnectionBeforeTour.RefreshDateAndTime': '画面更新時の日時',
  'ConnectionBeforeTour.StartDate': '{year}年{month}月{day}日 {time}',
  'ConnectionBeforeTour.Refresh': '画面を更新する',
  'ConnectionButton.Guide.Start': 'はじめる',
  'ConnectionButton.User.Start': 'はじめる',
  'ConnectionBrowse.BrowseMode': '閲覧モードで入る',
  'ConnectionBrowse.BrowseModeForVtour': 'ツアーを見学する',
  'ConnectionModal.DescriptionForUser1': '「{title}」のビューワーを使うには、',
  'ConnectionModal.DescriptionForUser2': '英字小文字',
  'ConnectionModal.DescriptionForUser3': 'でニックネームを入れてください。',
  'ConnectionModal.DescriptionForGuide':
    '「{title}」のビューワーを使うには、ニックネームを入れてください。',
  'ConnectionModal.Current': '現在の参加者 {limit} 名',
  'ConnectionModal.Maximum': '（参加定員 {limit} 名）',
  'ConnectionModal.Full1': '「{title}」のツアーは',
  'ConnectionModal.Full2': '参加上限に達しています。見学のみできます。',
  'ConnectionParticipants.Current': '現在の参加者数：',
  'ConnectionParticipants.PeopleJA': '人',
  'ConnectionParticipants.Maximum': '（参加できる人数: {limit} 人）',
  'VmapContainer.LocateButton.ConfrimMessage':
    '「現在地」モードに切り替わります。他のユーザーにあなたの位置情報が共有されます。よろしいですか？',
  'UserList.Guide': 'ガイド',
  'VmapContainer.FinishedTour.AlertMessage':
    'ツアーの終了時間となりました。ご参加ありがとうございました。',
  'VmapContainer.ExitButton.ConfirmMessage': 'このビューワーを終了しますか？',
  'VmapContainer.DeportedUser.AlertMessage':
    'ガイドの操作により、ツアー参加者から外されました。「見学モード」に切り替わります。',
  'StrolyMap.DeportUser.ConfirmMessage': 'この参加者を非表示にしますか？',
  'StrolyMap.LocateButton.AlertMessage': '現在地は地図の範囲外です。',
  'LandmarkList.All': 'すべて',
  'LandmarkList.None': '表示しない',
  'Landmark.Move': 'スポットを確認する',
  'CommentConatainer.CheckLandmark': '✅「{landmark}」をチェックしました！',
  'CommentForm.ButtonLabel': 'コメント',
  'CommentSendMessage.Placeholder': 'Comment',
  'DisconnectionDialog.ConfirmMessage': 'このツアーを終了しますか？',
  'DisconnectionDialog.Notice': '終了すると',
  'DisconnectionDialog.Demerit1': '再入場時に、過去のメッセージが見れない。',
  'DisconnectionDialog.Demerit2-1':
    'ツアーの参加者数が上限を超えている場合は、',
  'DisconnectionDialog.Demerit2-2': '参加者として、はじめることができません。',
  'DisconnectionDialog.Cancel': 'キャンセル',
  'DisconnectionDialog.Exit': '終了する',
  'About.Function': '各ボタンの機能',
  'About.About': '使い方',
  'About.AboutDescription': '使い方の説明（この画面）を開きます。',
  'About.UserList': '参加者一覧',
  'About.UserListDescription':
    '地図上に存在する参加者の一覧を表示します。表示されている参加者をクリックすると、その参加者のところまでアバターを移動させることができます。',
  'About.Location': '現在地「GPS」',
  'About.LocationDescription1': '「現在地」モードに切り替わります。',
  'About.LocationDescription2':
    '現在地を取得して、他のユーザーにあなたの位置情報を地図上で共有することができます。',
  'About.Exit': '終了',
  'About.ExitDescription': '地図から退出します。',
  'About.Comment': 'コメント機能とは？',
  'About.CommentDescription':
    '地図に参加しているアバター同士で、絵文字やコメントによるチャット機能による気軽なコミュニケーションが可能です。',
  'Infomation.Title': 'Stroly Viewerとは？',
  'Infomation.Description':
    'Stroly Viewerでは、Stroly.comで作成されたイラスト地図の中を、お好きに散策することができます。また、チャット機能がありますので、同時に参加している複数のユーザーと地図上でのコミュニケーションもお楽しみいただけます。',
};

export const en = {
  'ConnectionName.Nickname': 'Nickname',
  'ConnectionName.Guidename': 'Guide name',
  'ConnectionName.Placeholder': 'Lowercase letters only.',
  'ConnectionName.Error': 'Enter your nickname in lowercase.',
  'ConnectionStartDate.DateAndTime': 'Start time & Date',
  'ConnectionStartDate.StartDateTime': '{year}/{month}/{day} {time}',
  'ConnectionBeforeTour.DateAndTime': 'Start time & Date',
  'ConnectionBeforeTour.Message':
    'You can enter from 5 minutes before the start.',
  'ConnectionBeforeTour.RefreshDateAndTime': 'Last time viewed',
  'ConnectionBeforeTour.StartDate': '{year}/{month}/{day} {time}',
  'ConnectionBeforeTour.Refresh': 'Refresh this screen',
  'ConnectionButton.Guide.Start': 'Start the tour',
  'ConnectionButton.User.Start': 'Join as a participant',
  'ConnectionBrowse.BrowseMode': 'Join as an observer',
  'ConnectionBrowse.BrowseModeForVtour': 'Join as an observer',
  'ConnectionModal.DescriptionForUser1': 'Enter your nickname using ',
  'ConnectionModal.DescriptionForUser2': 'lowercase',
  'ConnectionModal.DescriptionForUser3':
    ' letters to start using the "{title}" viewer.',
  'ConnectionModal.DescriptionForGuide':
    'To start the virtual tour of "{title}", set nickname and click the "START THE TOUR" button.',
  'ConnectionModal.Current': 'Current number of participants {limit}',
  'ConnectionModal.Maximum': '(Capacity {limit} participants)',
  'ConnectionModal.Full1':
    'The tour for "{title}" has reached the number of allowed participants.',
  'ConnectionModal.Full2': 'You can join as an observer.',
  'ConnectionParticipants.Current': 'Current number of participants',
  'ConnectionParticipants.PeopleJA': ' ',
  'ConnectionParticipants.Maximum': '(Capacity {limit} participants)',
  'VmapContainer.LocateButton.ConfrimMessage':
    'Switch to "current location" mode. Your current location information will be used to share with others. Is that OK?',
  'UserList.Guide': 'Guide',
  'VmapContainer.FinishedTour.AlertMessage':
    'This tour has ended. Thank you for joining!',
  'VmapContainer.ExitButton.ConfirmMessage': 'Do you want to leave the viewer?',
  'VmapContainer.DeportedUser.AlertMessage':
    'You have been removed as a participant by the Guide. You can rejoin the tour as an Observer.',
  'StrolyMap.DeportUser.ConfirmMessage': 'Do you want to ban this participant?',
  'StrolyMap.LocateButton.AlertMessage':
    'Your current location is out of map area.',
  'LandmarkList.All': 'All',
  'LandmarkList.None': 'None',
  'Landmark.Move': 'Check the spot',
  'CommentConatainer.CheckLandmark': '✅ Take a look at "{landmark}"',
  'CommentForm.ButtonLabel': 'MESSAGE',
  'CommentSendMessage.Placeholder': 'Message',
  'DisconnectionDialog.ConfirmMessage': 'Do you want to leave the viewer?',
  'DisconnectionDialog.Notice': 'Notice',
  'DisconnectionDialog.Demerit1':
    "You can't see the past messages when you reenter.",
  'DisconnectionDialog.Demerit2-1':
    'If the number of participants in a virtual tour exceeds the limit, ',
  'DisconnectionDialog.Demerit2-2': 'you cannot start as a participant.',
  'DisconnectionDialog.Cancel': 'CANCEL',
  'DisconnectionDialog.Exit': 'EXIT',
  'About.Function': 'Icon Description',
  'About.About': 'Instructions Menu',
  'About.AboutDescription': 'Opens the Instructions Menu (this screen).',
  'About.UserList': 'List of Participants',
  'About.UserListDescription':
    'Displays a list of the participants on the map. You can move your character to the participant by clicking his/her character.',
  'About.Location': 'Current "GPS" Location',
  'About.LocationDescription1':
    'Switches to "Current Location" mode to locate your current position in the map.',
  'About.LocationDescription2':
    'You can share your location information with other users.',
  'About.Exit': 'Exit',
  'About.ExitDescription': 'Exit from the map',
  'About.Comment': 'What is the Comments Function?',
  'About.CommentDescription':
    'Stroly Viewer features an easy way to communicate with other users. You can exchange messages, send emojis, and maximize your experience on the map.',
  'Infomation.Title': 'What is a Stroly Viwer?',
  'Infomation.Description':
    'Stroly Viwer allows multiple users to explore the illustrated maps uploaded on Stroly.com and makes it easy to exchange messages with others.',
};
