import React from 'react';

import { EmojiName } from '../../../../domains/ws/emoji';

type Props = {
  emoji: EmojiName;
  label: string;
  handleEmojiClick: (emoji: EmojiName) => void;
};

const CommentEmoji = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    emojiButton: {
      position: 'relative',
      marginLeft: '8px',
      width: '32px',
      height: '32px',
      boxSizing: 'border-box',
      borderRadius: '50%',
      background: 'rgba(50, 50, 50, .6)',
    },
    emoji: {
      position: 'absolute',
      display: 'block',
      width: '24px',
      fontSize: '16px',
      cursor: 'pointer',
      top: '50%',
      left: '45%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [isEnabled, setIsEnabled] = React.useState(true);
  const handleEmojiClick = React.useCallback(
    (emoji: EmojiName): void => {
      if (isEnabled) {
        props.handleEmojiClick(emoji);
        setIsEnabled(false);

        setTimeout(() => {
          setIsEnabled(true);
        }, 1000);
      }
    },
    [isEnabled, props],
  );

  return (
    <div style={style.emojiButton}>
      <span
        role="img"
        aria-label={props.label}
        style={style.emoji}
        onClick={() => handleEmojiClick(props.emoji)}>
        {props.emoji}
      </span>
    </div>
  );
});

export default CommentEmoji;
