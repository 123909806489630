import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserType } from '../../../../domains/user/user.model';

type Props = {
  participants: number;
  limit: number;
  userType: UserType;
};

const ConnectionParticipants = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      fontSize: '14px',
      margin: '8px auto',
      textAlign: 'center',
      maxWidth: '288px',
    },
    participants: {
      fontSize: '18px',
    },
    maximum: {
      color: props.userType === UserType.Guide ? '#fff' : '#000',
      fontSize: '12px',
    },
  };

  const participants = props.participants ? (
    <div>
      <p style={style.p}>
        <FormattedMessage id="ConnectionParticipants.Current" />{' '}
        <span style={style.participants}>{props.participants}</span>{' '}
        <FormattedMessage id="ConnectionParticipants.PeopleJA" />
        <br />
        <span style={style.maximum}>
          <FormattedMessage
            id="ConnectionParticipants.Maximum"
            values={{ limit: props.limit }}
          />
        </span>
      </p>
    </div>
  ) : null;

  return participants;
});

export default ConnectionParticipants;
