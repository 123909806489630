import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  vtourUrl: string;
  handleButtonClick: () => void;
};

const VtourButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      marginTop: '16px',
      width: '264px',
      height: '40px',
      lineHeight: '32px',
      borderRadius: '25px',
      border: 0,
      fontSize: '12px',
      fontWeight: 500,
    },
    enabled: {
      backgroundColor: '#feb100',
      color: '#000',
    },
    disabled: {
      cursor: 'not-allowed',
      backgroundColor: '#ffe8b3',
      color: '#b3b3b3',
    },
  };

  const hover = css({
    ':hover': {
      backgroundColor: '#eba910!important',
    },
  });

  const buttonStyle = !!!props.vtourUrl
    ? { ...style.button, ...style.disabled }
    : { ...style.button, ...style.enabled };

  return (
    <Button
      style={buttonStyle}
      css={hover}
      variant="contained"
      onClick={props.handleButtonClick}
      disabled={!!!props.vtourUrl}>
      <FormattedMessage id="VtourButton.Enter" />
    </Button>
  );
});

export default VtourButton;
