import * as L from 'leaflet';

import { LatLng } from '../map/map.model';
import { Character } from '../user/character';
import { VTourIconColor } from '../../core/constants';
import { UserType } from '../user/user.model';

export class LocationMarker extends L.Marker {
  constructor(latlng: LatLng, character: Character) {
    super([latlng.lat, latlng.lng], {
      icon: LocationMarker.createIcon(character),
      draggable: false,
      riseOnHover: true,
      riseOffset: -2000,
    });
  }

  static getIsEmoji(message: string | undefined): boolean {
    if (!message) {
      return false;
    }
    return /^(👍|❤️|❗️)$/.test(message);
  }

  static createIcon(character: Character, message?: string) {
    const isGuide = character.getUserType() === UserType.Guide;
    const MerkerWidth = 176;
    const iconSize = isGuide ? 40 : 32;
    const iconBorderWidth = 2;
    const iconShadowWidth = 4;
    const iconColor = isGuide ? '#000' : VTourIconColor[character.getInitial()];

    const style = {
      marker: `
        position: relative;
        margin: 0;
        padding: 0;
        width: ${MerkerWidth}px;;
        text-align: center;
      `,
      popup: `
        position: fixed;
        bottom: 26px;
        width: ${MerkerWidth}px;
        text-aling: center;
        filter: drop-shadow(0px 2px 4px #424242);
      `,
      comment: `
        display: inline-block;
        text-align: left;
        border-radius: 8px;
        background-color: #fff;
        color: #000;
        padding: 4px 12px;
        opacity: 1;
        word-wrap: break-word;
        hyphens: auto;
        font-size: 14px;
        font-weight: bold;
        max-width: ${MerkerWidth}px;
      `,
      message: `
        font-size: 26px;
      `,
      bubble: `
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -6px;
        border: 6px solid transparent;
        opacity: 1;
        border-top: 6px solid #fff;
      `,
      character: `
        display: block;
        width: ${iconSize}px;
        height: ${iconSize}px;
        border-radius: 50%;
        border: ${iconBorderWidth}px solid #fff;
        vertical-align: middle;
        background-color: ${isGuide ? '#fff' : iconColor};
        box-shadow: 0 0 0 ${iconShadowWidth}px ${iconColor};
        margin: 0 auto 8px;
        filter: drop-shadow(0px 2px 2px #424242);
      `,
      name: `
        margin: 0;
        padding: 4px 8px;
        font-size: ${isGuide ? 14 : 12}px;
        text-align: center;
        color: #333;
        width: 100%;
        font-weight: bold;
        background-color: rgba(255, 255, 255, ${isGuide ? '.8' : '.4'})
      `,
    };

    const comment = LocationMarker.getIsEmoji(message)
      ? `<span style="${style.message}">${message}</span>`
      : LocationMarker.encodeMessage(message);

    const popup = message
      ? `<div style="${style.popup}">
          <div style="${style.comment}">${comment}</div>
          <div style="${style.bubble}"></div>
        </div>`
      : '';

    return L.divIcon({
      className: 'marker-icon',
      // iconAnchor: [14, 14],
      iconAnchor: [
        MerkerWidth / 2,
        iconSize + iconBorderWidth * 2 + iconShadowWidth,
      ],
      popupAnchor: [0, 0],
      html: `
        <div style="${style.marker}">
          ${popup}
          <img
            src="/images/characters/${
              isGuide ? 'in_guide' : character.getInitial()
            }.svg"
            width="${iconSize}"
            height="${iconSize}"
            style="${style.character}"
          />
          <span style="${style.name}">${
        isGuide ? 'ガイド: ' : ''
      }${character.getName()}</span>
        </div>
      `,
    });
  }

  static encodeMessage(string: String | undefined) {
    if (!string) {
      return string;
    }
    return string
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
  }
}
