import React from 'react';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  handleButtonClick(): void;
};

const ExitButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    image: {
      verticalAlign: 'middle',
      marginLeft: '1px',
    },
  };

  const exit = css({
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'block',
    padding: 0,
    border: 0,
    backgroundColor: '#eee',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    filter: 'drop-shadow(0px 0 2px #333)',
    '@media(max-width: 959px)': {
      top: '16px',
      bottom: 'unset',
    },
  });

  return (
    <button onClick={props.handleButtonClick} css={exit}>
      <img
        src="/images/icons/exit.svg"
        alt="exit"
        width="16"
        height="16"
        style={style.image}
      />
    </button>
  );
});

export default ExitButton;
