import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import DatePicker from '../../../core/components/DatePicker';

type Props = {
  startDate: string;
  handleDateChange: (date: moment.Moment) => void;
};

const VtourDate = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      margin: '16px 0 0 8px',
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      width: '104px',
      fontWeight: 500,
      fontSize: '12px',
      textAlign: 'left',
    },
    error: {
      marginTop: '4px',
      fontSize: '14px',
      color: '#f44336',
    },
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date(props.startDate || new Date()),
  );
  const handleDateChange = React.useCallback(
    (date: Date) => {
      const startDate = moment(date);
      props.handleDateChange(startDate);
    },
    [props],
  );
  React.useEffect(() => {
    handleDateChange(selectedDate);
    // eslint-disable-next-line
  }, [selectedDate]);

  return (
    <div style={style.div}>
      <span style={style.text}>
        <FormattedMessage id="VtourDate.DateAndTime" />
      </span>
      <div>
        <DatePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
    </div>
  );
});

export default VtourDate;
