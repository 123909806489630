import { UserType } from './user.model';

export class Character {
  private readonly name: string;
  private readonly userType: UserType;

  constructor(name: string, userType: UserType) {
    this.name = name;
    this.userType = userType;
  }

  getName() {
    return this.name;
  }

  getInitial() {
    return this.name.substr(0, 1);
  }

  getUserType() {
    return this.userType;
  }
}
