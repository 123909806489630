import React, { useContext, useState, useEffect, useRef } from 'react';
import { IntlShape } from 'react-intl';
import moment from 'moment';

import { MapService } from '../../../services/map.service';
import { WebSocketService } from '../../../hooks/useWebSocketService';
import { useMapUsecase } from '../../../hooks/useMapUsecase';
import { ActiveUser } from '../../../domains/map/map.model';
import { WebSocketSendService } from '../../../hooks/useWebSocketSendService';
import { Character } from '../../../domains/user/character';
import { ConnectionsRepository } from '../../../repositories/connections.repository';
import { UserType } from '../../../domains/user/user.model';
import { StateContext } from '../../../core/contexts';

type Props = {
  intl: IntlShape;
  isVtour: boolean;
  room: string;
  character: Character;
  webSocketSendService: WebSocketSendService;
  mapService: MapService;
  webSocketService: WebSocketService;
  setIsSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setExitUser: React.Dispatch<React.SetStateAction<string>>;
};

export const useLogoutService = (props: Props) => {
  const state = useContext(StateContext);
  const connectionsRepository = useRef(new ConnectionsRepository());
  const mapUsecase = useMapUsecase(props.mapService);

  const exit = () => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Exit',
    });

    if (
      props.isVtour &&
      state.isLoggedIn &&
      state.user.type === UserType.User
    ) {
      props.webSocketSendService.sendAvatarUserRemove(true);
      connectionsRepository.current.delete(
        props.room,
        String(state.user.token),
      );

      window.onbeforeunload = null;
      window.onpagehide = null;
      window.onunload = null;
    }

    if (state.isLoggedIn && state.user.type !== UserType.Browse) {
      props.webSocketSendService.sendAvatarUserRemove(true);
    }

    props.webSocketService.finish();
    window.location.reload();
  };

  function handleBeforeunload(event: BeforeUnloadEvent) {
    if (
      props.isVtour &&
      state.isLoggedIn &&
      state.user.type === UserType.User
    ) {
      event.returnValue = '本当に終了してもいいですか？';

      props.webSocketSendService.sendAvatarUserRemove(true);
      props.webSocketService.finish();
      connectionsRepository.current.delete(
        props.room,
        String(state.user.token),
      );

      window.onpagehide = null;
      window.onunload = null;

      return;
    }

    if (state.isLoggedIn && state.user.type !== UserType.Browse) {
      props.webSocketSendService.sendAvatarUserRemove(true);
      props.webSocketService.finish();
      window.onpagehide = null;
      window.onunload = null;
    }
  }

  function handlePagehideOrUnload(event: PageTransitionEvent | Event) {
    if (
      !props.isVtour &&
      state.isLoggedIn &&
      state.user.type !== UserType.Browse
    ) {
      props.webSocketSendService.sendAvatarUserRemove(true);
      props.webSocketService.finish();
    }
  }

  const timer = useRef<NodeJS.Timeout>();
  const activeUsers = useRef(state.activeUsers);
  useEffect(() => {
    activeUsers.current = state.activeUsers;
  }, [state.activeUsers]);
  function checkUnusedUser() {
    if (!state.isLoggedIn) {
      return;
    }

    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => {
      const now = moment();
      Object.values(activeUsers.current).forEach((activeUser: ActiveUser) => {
        if (
          state.user.token !== activeUser.token &&
          now.diff(activeUser.timestamp) > 1000 * 60 * 4
        ) {
          mapUsecase.removeUser(activeUser);
          props.setExitUser(activeUser.name);
          props.setIsSnackbarOpen(true);

          if (props.isVtour) {
            connectionsRepository.current.delete(
              props.room,
              String(activeUser.token),
            );
          }
        }
      });
    }, 1000 * 60);
  }

  function handleVisibilityChange() {
    if (document.hidden === true) {
      if (timer.current) {
        clearInterval(timer.current);
      }
    }

    if (document.visibilityState === 'visible') {
      checkUnusedUser();
      if (
        !(
          state.isLoggedIn &&
          props.isVtour &&
          state.user.type === UserType.User
        )
      ) {
        return;
      }
      // todo "value provided is not in a recognized RFC2822 or ISO format." という警告が出ているから直す
      const now = moment().utc();
      const lastTimestamp = moment(sessionStorage.getItem('timestamp'));
      // const lastTimestamp = activeUsers.current[state.user.token]?.timestamp;

      if (now.diff(lastTimestamp) > 1000 * 60 * 4) {
        alert('一定時間利用がなかったため、地図から退出します。');
        window.location.reload();
        return;
      }
      sessionStorage.setItem('timestamp', moment().utc().toString());
    }
  }

  useEffect(() => {
    checkUnusedUser();
    sessionStorage.removeItem('timestamp');
    window.document.onvisibilitychange = () => handleVisibilityChange();
    window.onbeforeunload = (event: BeforeUnloadEvent) =>
      handleBeforeunload(event);
    window.onpagehide = (event: PageTransitionEvent) =>
      handlePagehideOrUnload(event);
    window.onunload = (event: Event) => handlePagehideOrUnload(event);

    return () => {
      sessionStorage.removeItem('timestamp');
      window.document.onvisibilitychange = null;
      window.onbeforeunload = null;
      window.onpagehide = null;
      window.onunload = null;
    };
    // eslint-disable-next-line
  }, [props.character, state.isLoggedIn, state.user.type]);

  const [disconnectionDialogOpen, setDisconnectionDialogOpen] = useState(false);
  function openExitDialog() {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'OpenExitDialog',
    });

    setDisconnectionDialogOpen(true);
  }

  function cancelExit() {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'CancelExit',
    });

    setDisconnectionDialogOpen(false);
  }

  return {
    disconnectionDialogOpen,
    exit,
    openExitDialog,
    cancelExit,
  };
};
