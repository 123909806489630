import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

type Props = {
  isDisabled: boolean;
  isVtour: boolean;
  handleClick: () => void;
};

const ConnectionButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      width: '264px',
      height: '40px',
      lineHeight: '32px',
      fontSize: '12px',
      fontWeight: 500,
      borderRadius: '25px',
    },
    enabled: {
      backgroundColor: '#eab910',
      color: '#000',
    },
    disabled: {
      cursor: 'not-allowed',
      backgroundColor: '#f4da9f',
      color: 'rgba(0, 0, 0, .5)',
    },
  };

  const i18nId = props.isVtour
    ? 'ConnectionButton.Guide.Start'
    : 'ConnectionButton.User.Start';

  const buttonStyle = props.isDisabled
    ? { ...style.button, ...style.disabled }
    : { ...style.button, ...style.enabled };

  return (
    <Button
      style={buttonStyle}
      variant="contained"
      onClick={props.handleClick}
      disabled={props.isDisabled}>
      <FormattedMessage id={i18nId} />
    </Button>
  );
});

export default ConnectionButton;
