import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import ConnectionBeforeTour from './ConnectionBeforeTour';

type Props = {
  isBeforeTour: boolean;
  startDate: moment.Moment | undefined;
  isFull: boolean;
  limit: number;
  children: React.ReactChild[];
};

const ConnectionUserSetting = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    full: {
      margin: '24px 0 0',
    },
    limit: {
      margin: '0 0 16px',
      fontSize: '14px',
      color: '#666',
    },
    border: {
      margin: '24px 0 0',
    },
  };

  const beforeTour = useMemo(
    () =>
      props.isBeforeTour && (
        <ConnectionBeforeTour startDate={props.startDate} />
      ),
    [props.isBeforeTour, props.startDate],
  );

  const full = useMemo(
    () =>
      props.isFull && (
        <>
          <p style={style.full}>
            <FormattedMessage
              id="ConnectionModal.Current"
              values={{ limit: props.limit }}
            />
          </p>
          <p style={style.limit}>
            <FormattedMessage
              id="ConnectionModal.Maximum"
              values={{ limit: props.limit }}
            />
          </p>
        </>
      ),
    [props.isFull, props.limit, style.full, style.limit],
  );

  return (
    <>
      {beforeTour}
      {full}
      {props.isBeforeTour || props.isFull || (
        <div style={style.border}>
          {props.children[0]}
          {props.children[1]}
          {props.children[2]}
        </div>
      )}

      {/* 「入場」ボタン */}
      {props.isFull || props.children[3]}

      {/* 「閲覧モード」ボタン */}
      {props.isBeforeTour || props.children[4]}
    </>
  );
});

export default ConnectionUserSetting;
