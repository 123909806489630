import React from 'react';
import { FormattedMessage } from 'react-intl';

const Infomation = React.memo(() => {
  const style: { [key: string]: React.CSSProperties } = {
    about: {
      padding: '16px',
      maxWidth: '384px',
    },
    title: {
      marginTop: 0,
      fontSize: '14px',
      color: '#000',
    },
    description: {
      marginTop: '32px',
      fontSize: '12px',
      textAlign: 'justify',
    },
  };

  return (
    <div style={style.about}>
      <h2 style={style.title}>
        <FormattedMessage id="Infomation.Title" />
      </h2>
      <p style={style.description}>
        <FormattedMessage id="Infomation.Description" />
      </p>
    </div>
  );
});

export default Infomation;
