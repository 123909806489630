import React from 'react';
import { FormattedMessage } from 'react-intl';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

import { User } from '../../../../domains/user/user.model';
import { VtourOptions } from '../../../../types/vmap.type';

import ConnectionTitle from '../shared/ConnectionTitle';
import ConnectionUserSetting from './ConnectionUserSetting';
import ConnectionParticipants from '../shared/ConnectionParticipants';
import ConnectionName from '../shared/ConnectionName';
import ConnectionButton from '../shared/ConnectionButton';
import ConnectionStartDate from '../shared/ConnectionStartDate';
import ConnectionBrowse from './ConnectionBrowse';

type Props = {
  isDisabled: boolean;
  user: User;
  vtourOptions: VtourOptions;
  title: string | undefined;
  name: string;
  availabilityInfo: {
    isBeforeTour: boolean;
    isFull: boolean;
    startDate: moment.Moment | undefined;
    participants: number;
  };
  nicknameError: boolean;
  handleNameChange: (name: string) => void;
  enterTheMap: () => void;
  dispatchUpdateUserToBrowse: () => Promise<void>;
};

const ConnectionUserModal = React.memo((props: Props) => {
  const modal = css({
    backgroundColor: '#fff',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: '8px',
    color: '#000',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '16px 36px',
    padding: '32px 88px 48px',
    '@media(max-width: 959px)': {
      margin: 0,
      padding: '32px 24px ',
      maxWidth: '336px',
    },
  });

  const waiting = css({
    margin: '48px auto 0',
    display: 'block',
  });

  const emphasis = css({
    color: '#fe6700',
    fontWeight: 700,
  });

  const description = props.availabilityInfo.isFull ? (
    <>
      <FormattedMessage
        id="ConnectionModal.Full1"
        values={{ title: props.title }}
      />
      <FormattedMessage id="ConnectionModal.Full2" />
    </>
  ) : (
    <>
      <FormattedMessage
        id="ConnectionModal.DescriptionForUser1"
        values={{ title: props.title }}
      />
      <span css={emphasis}>
        <FormattedMessage id="ConnectionModal.DescriptionForUser2" />
      </span>
      <FormattedMessage
        id="ConnectionModal.DescriptionForUser3"
        values={{ title: props.title }}
      />
    </>
  );

  return (
    <div css={modal}>
      {/* 同意書モーダル */}
      {/* {props.isVtour && props.userType !== UserType.Guide ? (
        <ConnectionConsent />
      ) : null} */}

      {/* タイトル */}
      <ConnectionTitle userType={props.user.type}>
        {description}
      </ConnectionTitle>
      {props.vtourOptions.isVtour && (
        <img
          src="/images/waiting.svg"
          css={waiting}
          alt="waitng"
          width="176"
          height="49.11"
        />
      )}

      {/* キャラクター設定フォーム */}
      <ConnectionUserSetting
        isBeforeTour={props.availabilityInfo.isBeforeTour}
        startDate={props.availabilityInfo.startDate}
        isFull={props.availabilityInfo.isFull}
        limit={props.vtourOptions.limit}>
        <ConnectionStartDate
          vtourOptions={props.vtourOptions}
          startDate={props.availabilityInfo.startDate}
          userType={props.user.type}
        />
        <ConnectionParticipants
          participants={props.availabilityInfo.participants}
          limit={props.vtourOptions?.limit}
          userType={props.user.type}
        />
        <ConnectionName
          userType={props.user.type}
          name={props.name}
          isVtour={props.vtourOptions.isVtour}
          isError={props.nicknameError}
          handleChange={props.handleNameChange}
        />
        <ConnectionButton
          isDisabled={props.isDisabled}
          isVtour={props.vtourOptions.isVtour}
          handleClick={props.enterTheMap}
        />
        <ConnectionBrowse
          isVtour={props.vtourOptions.isVtour}
          isFull={props.availabilityInfo.isFull}
          handleBrowseModeClick={props.dispatchUpdateUserToBrowse}
        />
      </ConnectionUserSetting>
    </div>
  );
});

export default ConnectionUserModal;
