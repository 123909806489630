import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  isChatOpen: boolean;
  handleButtonClick: () => void;
  children: React.ReactChild;
};

const CommentAccordion = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    wrapper: {
      margin: 0,
      borderRadius: '0px',
      backgroundColor: 'transparent',
    },
    summary: {
      minHeight: 'auto',
    },
    details: {
      height: '100%',
      overflowY: 'scroll',
    },
  };

  const overrideStyle = css({
    '> .MuiAccordionSummary-root': {
      padding: 0,
    },
    ' .MuiAccordionDetails-root': {
      maxHeight: 'calc(100vh - 160px)',
      padding: '8px 0 16px',
      '@media(max-width: 959px)': {
        maxHeight: '190px',
        '@media (orientation:landscape)': {
          // 48px: コメント入力欄の高さ / 72px: アイコン＋名前ラベルの高さ+余白
          maxHeight: 'calc(50vh - 48px - 72px)',
        },
      },
    },
  });

  return (
    <Accordion
      expanded={props.isChatOpen}
      style={style.wrapper}
      css={overrideStyle}>
      <AccordionSummary
        aria-controls="panel1a-content"
        style={style.summary}
        onClick={props.handleButtonClick}></AccordionSummary>
      <AccordionDetails style={style.details}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
});

export default CommentAccordion;
