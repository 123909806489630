import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';

import { ja, en } from '../../core/i18n/vtour';
import { useLang } from '../../hooks/useLang';
import { updateIsLoading } from '../../core/reducer';
import { DispatchContext } from '../../core/contexts';
import { EventsRepository } from '../../repositories/events.repository';
import { Token } from '../../domains/user/token';
import { useCheckbox } from '../../hooks/useCheckbox';

import Overlay from '../../core/components/Overlay';
import VtourModal from './components/VtourModal';
import VtourDate from './components/VtourDate';
import VtourForm from './components/VtourForm';
import VtourCheck from './components/VtourCheck';
import VtourUrl from './components/VtourUrl';
import VtourButton from './components/VtourButton';

interface Props extends RouteComponentProps {}

const VtourContainer = (props: Props) => {
  const dispatch = React.useContext(DispatchContext);
  const [token, setToken] = useState<number>();
  const isCommentEnabled = useCheckbox(0);
  const isEmojiEnabled = useCheckbox(0);
  const isCommentListEnabled = useCheckbox(0);
  const isLocateEnabled = useCheckbox(0);
  const isSnsShareEnabled = useCheckbox(0);

  const [mapID, setMapID] = useState('');
  useEffect(() => {
    const mapID = props.location.pathname.split('/')[1];
    setMapID(mapID);
  }, [props.location.pathname, mapID]);

  const [vtourUrl, setVtourUrl] = useState('');
  const eventsRepository = React.useRef(new EventsRepository());
  function createVtourId() {
    dispatch(updateIsLoading(true));
    const token = new Token().getToken();
    setToken(token);
    const param = `${token},${mapID},${limit},${isCommentEnabled.value},${isEmojiEnabled.value},${isCommentListEnabled.value},${isLocateEnabled.value},${isSnsShareEnabled.value}`;
    const url = `/vtour/`;
    const protocol = token + mapID;

    eventsRepository.current
      .post(protocol, startDate)
      .then(() => {
        gtag('event', 'set options', {
          event_category: 'vtour',
          event_label: `[${param}]`,
        });

        setVtourUrl(`${url}${btoa(param)}`);
        dispatch(updateIsLoading(false));
      })
      .catch((error) => {
        dispatch(updateIsLoading(false));
        props.history.push(
          `/error/${protocol}`,
          props.history.location.pathname,
        );
      });
  }

  const [startDate, setStartDate] = useState<string>('');
  function convertDate(date: moment.Moment): string {
    return date?.format('YYYY-MM-DDTHH:mm');
  }
  useEffect(() => {
    // const date = new Date();
    // const utc = date.getTime();
    const date = moment();
    setStartDate(convertDate(date));
  }, []);

  function updateStartDate(date: moment.Moment): void {
    setStartDate(convertDate(date));
  }

  const [limit, setLimit] = useState(20);
  useEffect(() => {
    setVtourUrl('');
  }, [
    startDate,
    limit,
    isCommentEnabled.value,
    isEmojiEnabled.value,
    isCommentListEnabled.value,
    isLocateEnabled.value,
    isSnsShareEnabled.value,
  ]);

  function enterTheMap() {
    gtag('event', 'click', {
      event_category: 'vtour',
      event_label: `バーチャルツアー画面に入る`,
    });

    window.open(`${vtourUrl}/${token}`);
  }

  const userUrlRef = React.useRef<HTMLTextAreaElement>(null);
  const guideUrlRef = React.useRef<HTMLTextAreaElement>(null);
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const copyTargetStr = (target: string) => {
    if (target === '') return;

    const textArea =
      target === 'guide' ? guideUrlRef.current : userUrlRef.current;
    textArea!.select();
    document.execCommand('copy');
    textArea!.selectionStart = 0;
    textArea!.selectionEnd = 0;

    setOpenSnakbar(true);
  };

  const closeSnakbar = (event: React.SyntheticEvent, reason: string) => {
    if (reason === 'clickaway') return;
    setOpenSnakbar(false);
  };

  const lang = useLang({ ja, en });

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <Overlay
        isDisplay={true}
        options={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          } as { [key: string]: React.CSSProperties },
        }}>
        <VtourModal>
          <VtourDate startDate={startDate} handleDateChange={updateStartDate} />
          <VtourForm limit={limit} handleSelectChange={setLimit} />
          <VtourCheck
            isCommentEnabled={isCommentEnabled}
            isEmojiEnabled={isEmojiEnabled}
            isCommentListEnabled={isCommentListEnabled}
            isLocateEnabled={isLocateEnabled}
            isSnsShareEnabled={isSnsShareEnabled}
          />
          <VtourUrl
            vtourUrl={vtourUrl}
            token={token}
            openSnakbar={openSnakbar}
            userUrlRef={userUrlRef}
            guideUrlRef={guideUrlRef}
            handleButtonClick={createVtourId}
            handleUrlCopy={copyTargetStr}
            handleCloseSnakbar={closeSnakbar}
          />
          <VtourButton vtourUrl={vtourUrl} handleButtonClick={enterTheMap} />
        </VtourModal>
      </Overlay>
    </IntlProvider>
  );
};

export default VtourContainer;
