import React from 'react';

type Props = {
  children: React.ReactChild[];
};

const ConnectionGuideSetting = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    border: {
      textAlign: 'left',
      margin: '24px 16px',
      display: 'inline-block',
    },
    guideInfo: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      marginBottom: '40px',
    },
  };

  return (
    <>
      <div style={style.border}>
        <div style={style.guideInfo}>
          <span>{props.children[0]}</span>
          <span>{props.children[1]}</span>
        </div>
        {props.children[2]}
        {props.children[3]}
      </div>

      {/* ボタン */}
      <div style={style.buttonBox}>
        {/* 「入場」ボタン */}
        {props.children[4]}
      </div>
    </>
  );
});

export default ConnectionGuideSetting;
