import { useCallback, useContext, useEffect, useState } from 'react';

import { StateContext, DispatchContext } from '../../../core/contexts';
import { updateIsCommentListOpen } from '../../../core/reducer';

export const useCommentService = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const [readed, setReaded] = useState(0);
  const [unReaded, setUnReaded] = useState(0);
  useEffect(() => {
    const commentToken = state?.comments[state.comments.length - 1]?.token;
    if (!commentToken) {
      return;
    }
    if (state.isChatOpen) {
      setReaded(state.comments.length);
      setTimeout(() => {
        setUnReaded(0);
      }, 800);
      return;
    }
    if (commentToken === state.user.token) {
      setReaded(state.comments.length);
      return;
    }
    setUnReaded(state.comments.length - readed);
    // eslint-disable-next-line
  }, [state?.comments, state?.isChatOpen, readed, state?.user.token]);

  const dispatchUpdateIsCommentListOpen = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: state.isChatOpen ? 'Close Comments' : 'Open Comments',
    });

    dispatch(updateIsCommentListOpen(!state.isChatOpen));
  }, [dispatch, state?.isChatOpen]);

  /**
   * todo: ランドマークのコメント機能を削除したため、以下のメソッドはもはや必要ない
   * todo: ただ、地図上の任意の場所をつぶやきたいという需要はありそうなので、
   * todo: 使い回せるようにとりあえずコメントアウトして残しておく。
   */
  // function handleLandmarkCommentClick(landmark: {
  //   name: string | undefined;
  //   xy: LatLng;
  // }) {
  //   const date = moment().format('YYYY/MM/DD HH:mm:ss');
  //   const id = date + Math.random() + props.user.token;

  //   const message = props.intl.formatMessage(
  //     { id: 'CommentConatainer.CheckLandmark' },
  //     { landmark: props.landmark.name },
  //   );
  //   const commentMessage: CommentMessage = {
  //     id,
  //     date,
  //     token: props.user.token,
  //     color: props.user.color,
  //     name: props.name,
  //     characterType: props.characterType,
  //     latlng: landmark.xy,
  //     comment: message,
  //     type: 'landmark',
  //     room: props.protocol,
  //   };
  //   commentWS.send(commentMessage);

  //   gtag('event', 'click', {
  //     event_category: 'chat',
  //     event_label: 'landmark',
  //   });
  // }

  return { unReaded, state, dispatch, dispatchUpdateIsCommentListOpen };
};
