import { useContext, useEffect } from 'react';
import { IntlShape } from 'react-intl';

import { WebSocketSendService } from './useWebSocketSendService';
import { updateIsDisabled, updateIsSharing } from '../core/reducer';
import { StateContext, DispatchContext } from '../core/contexts';
import { LocationMessage, ActionType } from '../domains/ws/message.model';
import { MapService } from '../services/map.service';
import { Character } from '../domains/user/character';
import { UserType } from '../domains/user/user.model';
import * as Constants from '../core/constants';

type Props = {
  intl: IntlShape;
  protocol: string;
  character: Character;
  mapID: string;
  mapService: MapService;
  isLoggedIn: boolean;
  webSocketSendService: WebSocketSendService;
  sendDatatoWS<Message>(data: Message): void;
};

export const useMapEventListener = (props: Props) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  let timer!: NodeJS.Timeout;

  function handleLocationFound(event: L.LeafletEvent) {
    clearInterval(timer);
    dispatch(updateIsDisabled(true));

    console.log(
      `Get current location: ${event.latlng.lat}, ${event.latlng.lng}`,
    );

    const isInMap = props.mapService.getIsInMap(event.latlng);
    if (!isInMap) {
      const message = props.intl.formatMessage({
        id: 'StrolyMap.LocateButton.AlertMessage',
      });
      alert(message);
      props.mapService.stopGettingCurrentLocation();
      return;
    }

    props.webSocketSendService.sendAvatarUserRemove(false);

    const xy = props.mapService.stroly.latLngToStroly({
      lat: event.latlng.lat,
      lng: event.latlng.lng > 180 ? event.latlng.lng - 360 : event.latlng.lng,
    });

    const locationMassage: LocationMessage = {
      room: props.protocol,
      token: state.user.token,
      color: state.user.color,
      id: new Date().getTime(),
      name: props.character.getName(),
      userType: props.character.getUserType(),
      lat: xy.lat,
      lng: xy.lng,
      task: 'location',
      action: ActionType.Location,
    };
    props.sendDatatoWS<LocationMessage>(locationMassage);

    dispatch(updateIsSharing(true));

    const zoomScale = props.mapService.strolyMap.getZoom();
    const date = new Date().toISOString();
    gtag('event', 'found', {
      event_category: 'location',
      event_label: JSON.stringify([
        props.mapID,
        event.latlng.lat,
        event.latlng.lng,
        xy.lat,
        xy.lng,
        zoomScale,
        date,
      ]),
    });

    timer = setInterval(() => {
      props.sendDatatoWS<LocationMessage>(locationMassage);
    }, 10000);
  }

  function handleLocationStop() {
    clearInterval(timer);
    const locationMassage: LocationMessage = {
      room: props.protocol,
      token: state.user.token,
      color: state.user.color,
      id: new Date().getTime(),
      name: props.character.getName(),
      userType: props.character.getUserType(),
      lat: NaN,
      lng: NaN,
      task: 'removeLocation',
      action: ActionType.Location,
    };
    props.sendDatatoWS<LocationMessage>(locationMassage);
  }

  function handleLocationError() {
    console.error('Failed to get the current location.');
    dispatch(updateIsSharing(false));
    dispatch(updateIsDisabled(false));
  }

  function handleCentralMove(event: any) {
    const locationMassage: LocationMessage = {
      room: props.protocol,
      token: state.user.token,
      color: state.user.color,
      id: new Date().getTime(),
      name: props.character.getName(),
      userType: props.character.getUserType(),
      lat: event.lat,
      lng: event.lng,
      task: event.type,
      action: ActionType.Location,
    };
    if (state.user.type !== UserType.Browse) {
      props.sendDatatoWS<LocationMessage>(locationMassage);
    }
  }

  function handleDeportation(event: any) {
    if (state.user.type === UserType.Guide) {
      gtag('event', 'click', {
        event_category: 'vtour',
        event_label: 'deportation',
      });

      const message = props.intl.formatMessage({
        id: 'StrolyMap.DeportUser.ConfirmMessage',
      });
      const allowDeportation = window.confirm(message);

      if (allowDeportation) {
        const locationMassage: LocationMessage = {
          room: props.protocol,
          token: event.token,
          color: state.user.color,
          id: new Date().getTime(),
          name: Constants.DeportedUserName,
          userType: UserType.User,
          lat: NaN,
          lng: NaN,
          task: 'removeCentralMarker',
          action: ActionType.Location,
        };
        props.sendDatatoWS<LocationMessage>(locationMassage);
      }
    }
  }

  useEffect(() => {
    if (props.isLoggedIn) {
      // mapService.strolyMap.on('click', (event: L.LeafletEvent) => {
      //   const marker: Marker = {
      //     token,
      //     color,
      //     id: new Date().getTime(),
      //     lat: event.latlng.lat,
      //     lng: event.latlng.lng,
      //     task: 'put',
      //     action: ActionType.Location,
      //   };
      //   sendDatatoWS(JSON.stringify(marker));
      // });
      props.mapService.strolyMap.on('locationfound', handleLocationFound);
      props.mapService.strolyMap.on('locationstop', handleLocationStop);
      props.mapService.strolyMap.on('locationerror', handleLocationError);
      props.mapService.strolyMap.on('centralmove', handleCentralMove);
      props.mapService.strolyMap.on('deportation', handleDeportation);
    }

    return () => {
      props.mapService.strolyMap.off('locationfound', handleLocationFound);
      props.mapService.strolyMap.off('locationstop', handleLocationStop);
      props.mapService.strolyMap.off('locationerror', handleLocationError);
      props.mapService.strolyMap.off('centralmove', handleCentralMove);
      props.mapService.strolyMap.off('deportation', handleDeportation);
    };
    // eslint-disable-next-line
  }, [props.isLoggedIn, state.user.type]);
};
