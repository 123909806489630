import React, { memo, useCallback, useEffect } from 'react';
import moment from 'moment';

import { WebSocketRepository } from '../../../repositories/useWebSocketRepository';
import { WebSocketSendService } from '../../../hooks/useWebSocketSendService';
import { CommentMessage } from '../../../domains/ws/message.model';
import { EmojiName, Emoji } from '../../../domains/ws/emoji';
import { VtourOptions } from '../../../types/vmap.type';
import { MapService } from '../../../domains/map';
import { useCommentService } from '../hooks/useCommentService';

import Landmark from '../components/comment/Landmark';
import CommentForm from '../components/comment/CommentForm';
import CommentList from '../components/comment/CommentList';
import CommentAccordion from '../components/comment/CommentAccordion';
import CommentSendMessage from '../components/comment/CommentSendMessage';
import CommentEmoji from '../components/comment/CommentEmoji';
import CommentOpener from '../components/comment/CommentOpener';

type Props = {
  webSocketRepository: WebSocketRepository;
  webSocketSendService: WebSocketSendService;
  vtourOptions: VtourOptions;
  isConnected: boolean;
  isShareMode: boolean;
  isMobileDisplay: boolean;
  mapService: MapService;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
};

const CommentContainer = memo((props: Props) => {
  const commentService = useCommentService();

  function sendToWS(emoji?: EmojiName) {
    if (!emoji && !props.comment) {
      return;
    }

    const comment = emoji ? emoji : props.comment;

    const centerLatLng = props.isShareMode
      ? props.mapService.getLocationMarkerLatLng(
          commentService.state.user.token,
        )
      : props.mapService.getCentralLatLng();
    const date = moment().format('YYYY/MM/DD HH:mm:ss');
    const id = date + Math.random() + commentService.state.user.token;

    props.webSocketSendService.sendComment({
      id,
      date,
      latlng: centerLatLng,
      comment,
      type: Emoji.isEmoji(comment) ? 'emoji' : 'comment',
    });

    const chatType = Emoji.isEmoji(comment)
      ? new Emoji(comment).getEmojiName()
      : 'comment';

    gtag('event', 'click', {
      event_category: 'chat',
      event_label: chatType,
    });
  }

  useEffect(() => {
    if (props.isConnected) {
      sendToWS();
    }
    // eslint-disable-next-line
  }, [props.isConnected]);

  function panToComment(message: CommentMessage) {
    if (message.type !== 'landmark') {
      props.mapService.putCommentMarker(message);
    }

    props.mapService.panTo(message.latlng.lat, message.latlng.lng);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'CommentList',
    });
  }

  function panToLandmark() {
    props.mapService.panTo(
      commentService.state.landmarkContent.xy.lat,
      commentService.state.landmarkContent.xy.lng,
    );

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'Landmark',
    });
  }

  const setSelectedLandmarkMarker = useCallback(() => {
    props.mapService.setSelectedLandmarkMarker();

    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'landmark close',
    });
  }, [props.mapService]);

  return (
    <>
      <Landmark
        landmark={commentService.state?.landmarkContent}
        isMobile={props.isMobileDisplay}
        isLandmarkOpen={commentService.state?.isLandmarkOpen}
        setSelectedLandmarkMarker={setSelectedLandmarkMarker}
        handleMoveClick={panToLandmark}
        dispatch={commentService.dispatch}
      />
      <CommentForm
        userType={commentService.state?.user.type}
        isCommentEnabled={props.vtourOptions?.isCommentEnabled}
        isChatOpen={commentService.state?.isChatOpen}
        isEmojiEnabled={props.vtourOptions?.isEmojiEnabled}
        isMobileDisplay={props.isMobileDisplay}
        commentCount={commentService.state?.comments.length}
        renderCommentEmoji={(props: { emoji: EmojiName; label: string }) => (
          <CommentEmoji
            emoji={props.emoji}
            label={props.label}
            handleEmojiClick={sendToWS}
          />
        )}>
        <CommentAccordion
          isChatOpen={commentService.state?.isChatOpen}
          handleButtonClick={commentService.dispatchUpdateIsCommentListOpen}>
          <CommentList
            vtourOptions={props.vtourOptions}
            isChatOpen={commentService.state?.isChatOpen}
            comments={commentService.state?.comments}
            panToComment={panToComment}
          />
        </CommentAccordion>
        <CommentSendMessage
          comment={props.comment}
          isDisabled={!!!props.webSocketRepository.get()}
          handleChange={props.setComment}
          handleSendMessage={sendToWS}
        />
      </CommentForm>
      <CommentOpener
        unReaded={commentService.unReaded}
        handleButtonClick={commentService.dispatchUpdateIsCommentListOpen}
      />
    </>
  );
});

export default CommentContainer;
