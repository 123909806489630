import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { reducer, initialState } from './core/reducer';
import { StateContext, DispatchContext } from './core/contexts';

import VmapContainer from './modules/vmap/containers/VmapContainer';
import VtourContainer from './modules/vtour/VtourContainer';
import Error from './core/components/Error';
import InvalidUrlError from './core/components/InvalidUrlError';
import Backdrop from './core/components/Backdrop';

const App: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Route exact path="/" component={VmapContainer} />
          <Route exact path="/invalid/error" component={InvalidUrlError} />
          <Route exact path="/error/:mapid" component={Error} />
          <Route exact path="/:mapid" component={VmapContainer} />
          <Route exact path="/:mapid/vtour" component={VtourContainer} />
          <Route exact path="/vtour/:param" component={VmapContainer} />
          <Route exact path="/vtour/:param/:token" component={VmapContainer} />
        </BrowserRouter>
        <Backdrop isBackdropOpen={state.isLoading} />
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default App;
