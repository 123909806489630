import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import moment from 'moment';

type Props = {
  startDate: moment.Moment | undefined;
};

const ConnectionBeforeTour = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginTop: '16px',
      paddingTop: '16px',
    },
    p: {
      margin: 0,
      color: '#666',
      fontSize: '12px',
    },
    date: {
      color: '#000',
      fontSize: '18px',
    },
    text: {
      margin: '0 0 8px',
      color: '#c60',
      fontSize: '14px',
    },
    button: {
      margin: '16px 16px 0',
      maxWidth: '296px',
      width: 'calc(100% - 32px)',
      lineHeight: '32px',
      borderRadius: '25px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#fff',
    },
    enabled: {
      backgroundColor: '#333',
    },
    disabled: {
      backgroundColor: '#666',
      cursor: 'not-allowed',
    },
    refreshed: {
      marginTop: '16px',
      marginBottom: '0',
      paddingBottom: '24px',
      color: '#666',
      fontSize: '12px',
    },
  };

  const [currentDate, setCurrentDate] = React.useState(
    moment().format('YYYY/MM/DD HH:mm:ss'),
  );
  const [isDisabled, setDisabled] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setDisabled(true);

    const currentDate = moment();
    setCurrentDate(currentDate.format('YYYY/MM/DD HH:mm:ss'));
    if (moment(props.startDate).diff(currentDate) < 60 * 1000 * 5) {
      window.location.reload();
    }

    setDisabled(false);
  }, [props.startDate]);

  return (
    <div style={style.div}>
      <div>
        <p style={style.text}>
          <FormattedMessage id="ConnectionBeforeTour.Message" />
        </p>
        <p style={style.p}>
          <FormattedMessage id="ConnectionBeforeTour.DateAndTime" />
          {': '}
          <span style={style.date}>
            <FormattedMessage
              id="ConnectionBeforeTour.StartDate"
              values={{
                year: props.startDate?.format('YYYY'),
                month: props.startDate?.format('MM'),
                day: props.startDate?.format('DD'),
                time: props.startDate?.format('HH:mm'),
              }}
            />
          </span>
        </p>
      </div>

      <Button
        style={
          isDisabled
            ? { ...style.button, ...style.disabled }
            : { ...style.button, ...style.enabled }
        }
        variant="contained"
        onClick={handleClick}
        disabled={isDisabled}>
        <FormattedMessage id="ConnectionBeforeTour.Refresh" />
      </Button>

      <p style={style.refreshed}>
        <FormattedMessage id="ConnectionBeforeTour.RefreshDateAndTime" />
        {': '}
        {currentDate}
      </p>
    </div>
  );
});

export default ConnectionBeforeTour;
