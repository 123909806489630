import { LatLng } from '../map/map.model';
import { UserType } from '../user/user.model';

export enum ActionType {
  Location = 'location',
  Sendmessage = 'sendmessage',
}

export type CommentMessage = {
  id: string;
  date: string;
  token: number;
  color: string;
  name: string;
  userType: UserType;
  latlng: LatLng;
  comment: string;
  type: 'landmark' | 'comment' | 'emoji';
  room: string;
  action: ActionType;
};

export type LocationMessage = {
  id: number;
  room: string;
  token: number;
  color: string;
  name: string;
  userType: UserType;
  lat: number;
  lng: number;
  task: string;
  action: ActionType;
};
