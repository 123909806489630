import React from 'react';
import { FormattedMessage } from 'react-intl';

type IsEnabled = {
  value: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = {
  isCommentEnabled: IsEnabled;
  isEmojiEnabled: IsEnabled;
  isCommentListEnabled: IsEnabled;
  isLocateEnabled: IsEnabled;
  isSnsShareEnabled: IsEnabled;
};

const VtourCheck = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      textAlign: 'center',
      marginBottom: 0,
    },
    text: {
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    ul: {
      width: '320px',
      margin: '0 auto 16px',
      textAlign: 'left',
      listStyleType: 'none',
      padding: '0 0 0 16px',
    },
    li: {
      margin: '8px 0 0 -1.1em',
    },
    label: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      cursor: 'pointer',
      width: '16px',
      height: '16px',
    },
    labelText: {
      fontSize: '12px',
    },
  };

  return (
    <div style={style.div}>
      <ul style={style.ul}>
        <li style={style.li}>
          <label style={style.label}>
            <input
              type="checkbox"
              style={style.checkbox}
              value={props.isCommentEnabled.value}
              onChange={props.isCommentEnabled.handleChange}
            />
            <span style={style.labelText}>
              <FormattedMessage id="VtourCheck.AllowComment" />
            </span>
          </label>
        </li>
        <li style={style.li}>
          <label style={style.label}>
            <input
              type="checkbox"
              style={style.checkbox}
              value={props.isEmojiEnabled.value}
              onChange={props.isEmojiEnabled.handleChange}
            />
            <span style={style.labelText}>
              <FormattedMessage id="VtourCheck.AllowEmoji" />
            </span>
          </label>
        </li>
        <li style={style.li}>
          <label style={style.label}>
            <input
              type="checkbox"
              style={style.checkbox}
              value={props.isCommentListEnabled.value}
              onChange={props.isCommentListEnabled.handleChange}
            />
            <span style={style.labelText}>
              <FormattedMessage id="VtourCheck.AllowDisplayCommentList" />
            </span>
          </label>
        </li>
        <li style={style.li}>
          <label style={style.label}>
            <input
              type="checkbox"
              style={style.checkbox}
              value={props.isLocateEnabled.value}
              onChange={props.isLocateEnabled.handleChange}
            />
            <span style={style.labelText}>
              <FormattedMessage id="VtourCheck.AllowGetLocation" />
            </span>
          </label>
        </li>
        <li style={style.li}>
          <label style={style.label}>
            <input
              type="checkbox"
              style={style.checkbox}
              value={props.isSnsShareEnabled.value}
              onChange={props.isSnsShareEnabled.handleChange}
            />
            <span style={style.labelText}>
              <FormattedMessage id="VtourCheck.AllowSnsShare" />
            </span>
          </label>
        </li>
      </ul>
    </div>
  );
});

export default VtourCheck;
