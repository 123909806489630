import React from 'react';

import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';

import { UserType } from '../../../../domains/user/user.model';
import { EmojiName } from '../../../../domains/ws/emoji';

/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  userType: UserType;
  isCommentEnabled: boolean;
  isEmojiEnabled: boolean;
  isChatOpen: boolean;
  isMobileDisplay: boolean;
  commentCount: number;
  renderCommentEmoji: (props: {
    emoji: EmojiName;
    label: string;
  }) => JSX.Element;
  children: React.ReactChild[];
};

const CommentForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    drawer: {
      height: 0,
    },
    div: {
      display: 'flex',
      paddingLeft: '8px',
    },
    paper: {
      padding: '0 8px',
      alignItems: 'center',
      margin: '0',
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: '25px',
    },
    divider: {
      height: 24,
      margin: '8px',
    },
    emojis: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  };

  const wrapper = css({
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    zIndex: 1000,
    padding: '0 0 24px 16px',
    width: 'calc(100% - 16px)',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    filter: 'drop-shadow(0px 0 2px #333)',
    '@media (max-width: 959px)': {
      padding: '16px 0 16px 8px',
      maxHeight: '248px',
      width: 'calc(100vw - 8px)',
      maxWidth: 'unset',
      position: 'fixed',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .4) 90%,  rgba(0, 0, 0, 0) 100%)',
    },
  });

  const div = css({
    '> .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  });

  const anchorMinHeight = 90;
  const commentRowHeight = 36;

  const drawer = css({
    '.MuiBackdrop-root': {
      display: 'none',
    },
    '.MuiDrawer-paperAnchorBottom': {
      height: `${commentRowHeight * props.commentCount + anchorMinHeight}px`,
      maxHeight: '280px',
      backgroundColor: 'transparent',
      '@media (orientation:landscape)': {
        maxHeight: '50vh',
      },
    },
    '.MuiDrawer-paperAnchorRight': {
      width: '376px',
      height: '100%',
      background: 'rgba(0, 0, 0, .65)',
    },
  });

  return (
    <Drawer
      style={style.drawer}
      css={drawer}
      anchor={props.isMobileDisplay ? 'bottom' : 'right'}
      open={props.isChatOpen}
      ModalProps={{
        disableEnforceFocus: true,
      }}>
      <div css={wrapper}>
        {props.children[0]}
        {props.userType === UserType.Guide ||
        (props.userType === UserType.User && props.isCommentEnabled) ||
        (props.userType === UserType.User && props.isEmojiEnabled) ? (
          <div style={style.div} css={div}>
            {props.userType === UserType.Guide ||
            (props.userType === UserType.User && props.isCommentEnabled) ? (
              <Paper style={style.paper}>{props.children[1]}</Paper>
            ) : null}

            {props.userType === UserType.Guide ||
            (props.userType === UserType.User && props.isEmojiEnabled) ? (
              <div style={style.emojis}>
                {props.renderCommentEmoji({
                  emoji: EmojiName.like,
                  label: 'like',
                })}
                {props.renderCommentEmoji({
                  emoji: EmojiName.love,
                  label: 'love',
                })}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Drawer>
  );
});

export default CommentForm;
