export class Color {
  private readonly color: string;

  constructor(colorCode?: string) {
    this.color = this.createColor(colorCode);
  }

  getColor(): string {
    return this.color;
  }

  private createColor(colorCode?: string): string {
    if (colorCode && colorCode.length === 7) {
      const r = parseInt(colorCode.substring(1, 3), 16);
      const g = parseInt(colorCode.substring(3, 5), 16);
      const b = parseInt(colorCode.substring(5, 7), 16);

      if (!isNaN(r) && !isNaN(g) && !isNaN(b)) {
        return `rgba(${r},${g},${b},1)`;
      }
    }

    // const color = ((Math.random() * 0xffffff) | 0).toString(16);
    // return `#${('000000' + color).slice(-6)}`;
    const r = Math.round(Math.random() * 180);
    const g = Math.round(Math.random() * 180);
    const b = Math.round(Math.random() * 180);

    return `rgba(${r},${g},${b},1)`;
  }
}
