import React from 'react';
import { FormattedMessage } from 'react-intl';
import GpsFixed from '@material-ui/icons/GpsFixed';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

import { VtourOptions } from '../../../../types/vmap.type';

type Props = {
  vtourOptions: VtourOptions;
};

const About = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    about: {
      padding: '16px',
    },
    title: {
      marginTop: 0,
      fontSize: '14px',
      color: '#000',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    text: {
      margin: 0,
    },
    image: {
      padding: '4px 16px 4px 4px',
      boxSizing: 'border-box',
      width: '64px',
    },
    button: {
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
    aboutButton: {
      width: '48px',
      height: '48px',
      lineHeight: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
      paddingTop: '4px',
    },
    userListButton: {
      paddingTop: '8px',
      width: '48px',
      height: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    gpsButton: {
      margin: '4px 0 0 4px',
      width: '40px',
      height: '40px',
      lineHeight: '40px',
      paddingTop: '6px',
      boxShadow: '0 0 0 4px rgba(255,255,255,0.5)',
      filter: 'drop-shadow(0px 0 4px #333)',
    },
    exitButton: {
      position: 'relative',
      textAlign: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      filter: 'drop-shadow(0px 0 2px #333)',
      borderRadius: '50%',
      width: '48px',
      height: '48px',
    },
    exitButtonImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    commentButton: {
      width: '48px',
      height: '48px',
      lineHeight: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
      paddingTop: '4px',
    },
  };

  const li = css({
    li: {
      display: 'flex',
      padding: '16px 0',
      textAlign: 'left',
      fontSize: '14px',
      borderBottom: '1px dashed #666',
    },
    'li:last-of-type': {
      paddingBottom: 0,
      borderBottom: 'none',
    },
  });

  return (
    <div style={style.about}>
      <h2 style={style.title}>
        <FormattedMessage id="About.Function" />
      </h2>
      <ul style={style.ul} css={li}>
        <li>
          <div style={style.image}>
            <div style={{ ...style.button, ...style.aboutButton }}>
              <img
                src="/images/icons/about.svg"
                width="16"
                height="20"
                alt="about"
              />
            </div>
          </div>
          <p style={style.text}>
            <b>
              <FormattedMessage id="About.About" />
            </b>
            <br />
            <FormattedMessage id="About.AboutDescription" />
          </p>
        </li>
        <li style={style.li}>
          <div style={style.image}>
            <div style={{ ...style.button, ...style.userListButton }}>
              <img
                src="/images/icons/user.svg"
                alt="user"
                width="24"
                height="15.91"
              />
              <br />
              <img
                src="/images/icons/arrow_bottom_black.svg"
                alt="open"
                width="12"
                height="6.77"
                style={{ verticalAlign: '3px' }}
              />
            </div>
          </div>
          <p style={style.text}>
            <b>
              <FormattedMessage id="About.UserList" />
            </b>
            <br />
            <FormattedMessage id="About.UserListDescription" />
          </p>
        </li>
        {props.vtourOptions.isLocateEnabled && (
          <li style={style.li}>
            <div style={style.image}>
              <div style={{ ...style.button, ...style.gpsButton }}>
                <GpsFixed />
              </div>
            </div>
            <p style={style.text}>
              <b>
                <FormattedMessage id="About.Location" />
              </b>
              <br />
              <FormattedMessage id="About.LocationDescription1" />
              <br />
              <FormattedMessage id="About.LocationDescription2" />
            </p>
          </li>
        )}
        <li style={style.li}>
          <div style={style.image}>
            <div style={style.exitButton}>
              <img
                src="/images/icons/exit.svg"
                alt="exit"
                width="16"
                height="16"
                style={style.exitButtonImage}
              />
            </div>
          </div>
          <p style={style.text}>
            <b>
              <FormattedMessage id="About.Exit" />
            </b>
            <br />
            <FormattedMessage id="About.ExitDescription" />
          </p>
        </li>
        {(props.vtourOptions.isCommentEnabled ||
          props.vtourOptions.isEmojiEnabled) && (
          <li style={style.li}>
            <div style={style.image}>
              <div style={{ ...style.button, ...style.commentButton }}>
                <img
                  src="/images/icons/comment.svg"
                  width="23"
                  height="18"
                  alt="comment"
                />
              </div>
            </div>
            <p style={style.text}>
              <b>
                <FormattedMessage id="About.Comment" />
              </b>
              <br />
              <FormattedMessage id="About.CommentDescription" />
            </p>
          </li>
        )}
      </ul>
    </div>
  );
});

export default About;
