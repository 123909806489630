import React from 'react';
import { FormattedMessage } from 'react-intl';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

type Props = {
  isVtour: boolean;
  handleExitButtonClick(): void;
  handleCancelButtonClick(): void;
};

const DisconnectionDialog = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    img: {
      margin: '0 auto',
    },
    h2: {
      marginTop: '24px',
      fontSize: '14px',
      fontWeight: 500,
    },
    h3: {
      margin: '32px 0 0 0',
      paddingBottom: '8px',
    },
    span: {
      display: 'inline-block',
      fontSize: '12px',
      fontWeight: 500,
      borderBottom: '1px solid #999',
    },
    ul: {
      marginTop: '24px',
      paddingLeft: '16px',
      fontSize: '12px',
      textAlign: 'left',
    },
    div: {
      marginTop: '48px',
    },
    button: {
      width: '120px',
      height: '40px',
      lineHeight: '32px',
      borderRadius: '25px',
      fontSize: '12px',
      fontWeight: 500,
      color: '#000',
    },
  };

  const dialogStyle = css({
    textAlign: 'center',
    '.MuiDialog-paper': {
      margin: 0,
      padding: '32px 88px',
      borderRadius: '10px',
      '@media(max-width: 959px)': {
        padding: '32px',
      },
    },
    '.MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
    '@media(max-width: 959px)': {
      margin: '0 auto',
      maxWidth: '336px',
    },
  });

  const exitButtonStyle = css({
    marginLeft: '32px',
    backgroundColor: '#eab540',
    ':hover': {
      backgroundColor: '#eba910',
    },
  });

  const cancelButtonStyle: React.CSSProperties = {
    backgroundColor: '#d5d5d5',
    ...style.button,
  };

  return (
    <Dialog
      css={dialogStyle}
      open={true}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <img
        src="/images/stroly.png"
        alt="stroly"
        width="53"
        height="31"
        style={style.img}
      />
      <h2 style={style.h2}>
        {props.isVtour ? (
          <FormattedMessage id="DisconnectionDialog.ConfirmMessage" />
        ) : (
          <FormattedMessage id="VmapContainer.ExitButton.ConfirmMessage" />
        )}
      </h2>
      <h3 style={style.h3}>
        <span style={style.span}>
          <FormattedMessage id="DisconnectionDialog.Notice" />
        </span>
      </h3>
      <ul style={style.ul}>
        <li>
          <FormattedMessage id="DisconnectionDialog.Demerit1" />
        </li>
        {props.isVtour && (
          <li>
            <FormattedMessage id="DisconnectionDialog.Demerit2-1" />
            <br />
            <FormattedMessage id="DisconnectionDialog.Demerit2-2" />
          </li>
        )}
      </ul>
      <div style={style.div}>
        <Button
          style={cancelButtonStyle}
          variant="contained"
          onClick={props.handleCancelButtonClick}>
          <FormattedMessage id="DisconnectionDialog.Cancel" />
        </Button>
        <Button
          css={exitButtonStyle}
          style={style.button}
          variant="contained"
          onClick={props.handleExitButtonClick}>
          <FormattedMessage id="DisconnectionDialog.Exit" />
        </Button>
      </div>
    </Dialog>
  );
});

export default DisconnectionDialog;
