import React, { useCallback, useEffect, memo } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { History } from 'history';

import { useConnectionService } from './hooks/useConnectionService';
import { UserType } from '../../domains/user/user.model';
import { VtourOptions } from '../../types/vmap.type';
import { Character } from '../../domains/user/character';

import Overlay from '../../core/components/Overlay';
import ConnectionUserModal from './components/user/ConnectionUserModal';
import ConnectionGuideModal from './components/guide/ConnectionGuideModal';

type Props = {
  intl: IntlShape;
  history: History;
  vtourOptions: VtourOptions;
  name: string;
  character: Character;
  protocol: string;
  handleUserTypeSelected(userType: UserType): void;
  handleNameChange(name: string): void;
};

const ConnectionContainer = memo((props: Props) => {
  const connectionService = useConnectionService({
    history: props.history,
    protocol: props.protocol,
    limit: props.vtourOptions.limit,
    isVtour: props.vtourOptions.isVtour,
  });

  useEffect(() => {
    if (!props.vtourOptions?.isVtour) {
      return;
    }
    const message = props.intl.formatMessage({
      id: 'VmapContainer.FinishedTour.AlertMessage',
    });
    connectionService.checkVtouravailability(message);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (connectionService.state.user.type === UserType.Guide) {
      props.handleUserTypeSelected(UserType.Guide);
    }
    // eslint-disable-next-line
  }, []);

  const handleNameChange = useCallback(
    (name: string) => {
      props.handleNameChange(connectionService.checkInputedName(name));
    },
    [connectionService, props],
  );

  const enterTheMap = useCallback(() => {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: props.character.getUserType(),
    });

    connectionService.login();
  }, [connectionService, props.character]);

  return (
    <Overlay
      isDisplay={true}
      options={{
        style: {
          backgroundColor:
            connectionService.state.user.type === UserType.Guide
              ? 'rgba(0, 0, 0, 0.85)'
              : 'rgba(0, 0, 0, 0.6)',
        } as { [key: string]: React.CSSProperties },
      }}>
      {connectionService.state.user.type === UserType.Guide ? (
        <ConnectionGuideModal
          title={connectionService.state.metaData?.title}
          user={connectionService.state.user}
          vtourOptions={props.vtourOptions}
          name={props.name}
          isDisabled={connectionService.isDisabled}
          availabilityInfo={connectionService.availabilityInfo}
          handleNameChange={handleNameChange}
          enterTheMap={enterTheMap}
          nicknameError={
            connectionService.nicknameError
          }></ConnectionGuideModal>
      ) : (
        <ConnectionUserModal
          title={connectionService.state.metaData?.title}
          user={connectionService.state.user}
          vtourOptions={props.vtourOptions}
          name={props.name}
          isDisabled={connectionService.isDisabled}
          availabilityInfo={connectionService.availabilityInfo}
          handleNameChange={handleNameChange}
          enterTheMap={enterTheMap}
          dispatchUpdateUserToBrowse={
            connectionService.dispatchUpdateUserToBrowse
          }
          nicknameError={connectionService.nicknameError}></ConnectionUserModal>
      )}
    </Overlay>
  );
});

export default injectIntl(ConnectionContainer);
