import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { VtourOptions } from '../../../../types/vmap.type';
import { UserType } from '../../../../domains/user/user.model';

type Props = {
  startDate: moment.Moment | undefined;
  vtourOptions: VtourOptions;
  userType: UserType;
};

const ConnectionStartDate = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    text: {
      fontSize: '14px',
      margin: '0 auto',
      textAlign: 'center',
      maxWidth: '288px',
    },
    caption: {
      color: props.userType === UserType.Guide ? '#fff' : '#000',
      display: 'inline',
    },
    date: {
      margin: '0 0 16px',
      fontSize: '12px',
    },
    startDateValue: {
      fontSize: '18px',
    },
  };

  const startDate =
    props.vtourOptions.isVtour && props.startDate ? (
      <div>
        <p style={style.text}>
          <span style={style.caption}>
            <FormattedMessage id="ConnectionStartDate.DateAndTime" />
            {' : '}
          </span>
          <span style={style.date}>
            <FormattedMessage
              id="ConnectionStartDate.StartDateTime"
              values={{
                year: (
                  <span style={style.startDateValue}>
                    {props.startDate.format('YYYY')}
                  </span>
                ),
                month: (
                  <span style={style.startDateValue}>
                    {props.startDate.format('MM')}
                  </span>
                ),
                day: (
                  <span style={style.startDateValue}>
                    {props.startDate.format('DD')}
                  </span>
                ),
                time: (
                  <span style={style.startDateValue}>
                    {props.startDate.format('HH:mm')}
                  </span>
                ),
              }}
            />
          </span>
        </p>
      </div>
    ) : null;

  return startDate;
});

export default ConnectionStartDate;
