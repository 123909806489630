export enum UserType {
  Guide,
  User,
  Browse,
}

export type User = {
  type: UserType;
  color: string;
  token: number;
};
