import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
};

const DatePicker = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      padding: 0,
      width: '32px',
      height: '32px',
      backgroundColor: '#efefef',
      border: '1px solid #ccc',
      borderRadius: '0px',
    },
  };

  const datePicker = css({
    backgroundColor: '#fff',
    width: '200px',
    '.MuiInputBase-root': {
      fontSize: '16px',
    },
    '.MuiInputBase-input': {
      padding: '0 0 0 8px',
      height: '32px',
    },
    '.MuiInput-underline:before': {
      border: 'none',
    },
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        autoOk
        disablePast
        hideTabs
        ampm={false}
        value={props.selectedDate}
        onChange={props.setSelectedDate as any}
        allowKeyboardControl={false}
        leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
        rightArrowButtonProps={{ 'aria-label': 'Next month' }}
        format="yyyy/MM/dd HH:mm"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <button style={style.button}>
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                  width="13"
                  height="15"
                />
              </button>
            </InputAdornment>
          ),
        }}
        css={datePicker}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
