export enum EmojiName {
  like = '👍',
  love = '❤️',
  look = '❗️',
}

export class Emoji {
  private readonly emoji!: string;

  constructor(emoji: string) {
    if (!Emoji.isEmoji(emoji)) {
      throw new Error('It is not Emoji.');
    }
    this.emoji = emoji;
  }

  getEmojiName(): string {
    return Object.entries(EmojiName)
      .filter((emoji) => {
        return emoji[1] === this.emoji;
      })
      .map((emoji) => {
        return emoji[0];
      })[0];
  }

  static isEmoji(emoji: string): boolean {
    const isEmoji = Object.values(EmojiName).filter((emojiName) => {
      return emojiName === emoji;
    });

    return isEmoji.length > 0;
  }
}
