import React, { useRef, useState } from 'react';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import { History } from 'history';

import { StateContext, DispatchContext } from '../core/contexts';
import {
  updateUser,
  deleteComments,
  updateIsLoading,
  updateIsDisabled,
} from '../core/reducer';
import { WebSocketRepository } from '../repositories/useWebSocketRepository';
import { ConnectionsRepository } from '../repositories/connections.repository';
import { useWebSocketRepository } from '../repositories/useWebSocketRepository';
import { useMapUsecase } from './useMapUsecase';

import { MapService } from '../services/map.service';
import { UserType } from '../domains/user/user.model';
import { ActiveUser } from '../domains/map/map.model';
import { Character } from '../domains/user/character';
import { CommentMessage, LocationMessage } from '../domains/ws/message.model';
import * as Constants from '../core/constants';

export type WebSocketService = {
  isConnected: boolean;
  init: () => void;
  finish: () => void;
  getRepository: () => WebSocketRepository;
};

type Props = {
  intl: IntlShape;
  room: string;
  character: Character;
  mapService: MapService;
  isLoggedIn: boolean;
  isBrowseMode: boolean;
  isShareMode: boolean;
  history: History;
  setIsSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setExitUser: React.Dispatch<React.SetStateAction<string>>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
};

export const useWebSocketService = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const connectionsRepository = useRef(new ConnectionsRepository());
  const mapUsecase = useMapUsecase(props.mapService);
  const [isConnected, setIsConnected] = useState(false);

  /**
   * WebSocketの接続が開始された時に呼び出される処理
   */
  const handleWebSocketOpen = () => {
    dispatch(updateIsDisabled(false));
    createCentralMarker();
    setIsConnected(true);
  };

  const createCentralMarker = () => {
    if (props.isShareMode === false && props.isBrowseMode === false) {
      props.mapService.createCentralMarker(state.user.token, props.character);
    }
  };

  /**
   * WebSocketの接続が閉じた時に呼び出される処理
   */
  const handleWebSocketClose = (event: CloseEvent) => {
    setIsConnected(false);

    if (event.code === 1000 && props.isLoggedIn) {
      console.log(
        'Socket is closed. Reconnect will be attempted in 2 second.',
        `reason: ${event.code}`,
      );

      setTimeout(() => {
        webSocketRepository.connect();
      }, 2000);
      return;
    }

    webSocketRepository.clearHandler();
  };

  /**
   * WebSocketの接続にエラーが生じた時に呼び出される処理
   */
  const handleWebSocketError = (event: Event) => {
    console.error('WebSocket error: ', event);
  };

  /**
   * WebSocketからメッセージを受信した時に呼び出される処理
   */
  const handleWebSocketMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);

    if (message.action === 'sendmessage') {
      handleSendmessageRecieve(message);
      return;
    }

    if (message.action === 'location') {
      handleLocationRecieve(message);
      return;
    }
  };

  const handleSendmessageRecieve = (message: CommentMessage) => {
    mapUsecase.addCommentToCommentList(message);
    mapUsecase.setCommentToUser(message);
    if (state.user.token === message.token) {
      props.setComment('');
    }
  };

  const handleLocationRecieve = (message: LocationMessage) => {
    const movedUser = {
      ...message,
      timestamp: moment(),
    } as ActiveUser;
    const isMe = state.user.token === movedUser.token;

    if (state.user.token > movedUser.id) return;

    switch (movedUser.task) {
      case 'put':
        break;
      case 'move':
        break;
      case 'remove':
        break;

      /**
       * （現在地モード時）ユーザーが移動した時の処理
       */
      case 'location':
        mapUsecase.moveGpsUser(movedUser);
        mapUsecase.addUserToUserList(movedUser);
        dispatch(updateIsDisabled(false));
        break;

      /**
       * （現在地モード時）現在地モードからアバターモードに切り替わる時の処理
       */
      case 'removeLocation':
        mapUsecase.hideGpsUser(movedUser);
        dispatch(updateIsDisabled(false));
        break;

      /**
       * （アバターモード時）ユーザーが移動した時の処理
       */
      case 'centralmove':
        if (isMe) {
          sessionStorage.setItem('timestamp', moment().utc().toString());
        }

        mapUsecase.moveAvatarUser(movedUser);
        mapUsecase.addUserToUserList(movedUser);
        break;

      /**
       * （アバターモード時）アバターモードから現在地モードに切り替わる時の処理
       */
      case 'hideCentralMarker':
        mapUsecase.hideAvatarUser(movedUser);
        break;

      /**
       * （アバターモード時）終了時や強制ブラウザモード時の処理
       */
      case 'removeCentralMarker':
        mapUsecase.removeUser(movedUser);
        if (!isMe && movedUser.name !== Constants.DeportedUserName) {
          props.setExitUser(movedUser.name);
          props.setIsSnackbarOpen(true);
        }

        if (isMe && movedUser.name === Constants.DeportedUserName) {
          dispatch(
            updateUser({
              type: UserType.Browse,
            }),
          );

          const message = props.intl.formatMessage({
            id: 'VmapContainer.DeportedUser.AlertMessage',
          });
          alert(message);

          dispatch(updateIsLoading(true));
          connectionsRepository.current
            .delete(props.room, String(state.user.token))
            .then(() => {
              dispatch(updateIsLoading(false));
            })
            .catch(() => {
              dispatch(updateIsLoading(false));
              props.history.push(
                `/error/${props.room}`,
                props.history.location.pathname,
              );
            });
        }

        if (movedUser.name === Constants.DeportedUserName) {
          dispatch(deleteComments(movedUser.token));
        }
        break;
    }
  };

  const webSocketRepository = useWebSocketRepository(props.room, {
    handleWebSocketOpen,
    handleWebSocketClose,
    handleWebSocketError,
    handleWebSocketMessage,
  });

  const init = () => {
    webSocketRepository.connect();
  };

  const finish = () => {
    webSocketRepository.disconnect();
  };

  const getRepository = () => {
    return webSocketRepository;
  };

  return {
    isConnected,
    init,
    finish,
    getRepository,
  };
};
