import React, { ReactChild } from 'react';
import { UserType } from '../../../../domains/user/user.model';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  userType: UserType;
  children: ReactChild;
};

const ConnectionTitle = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    title: {
      position: 'relative',
    },
    link: {
      display: 'inline-block',
      color: '#424242',
      textDecoration: 'none',
    },
    titleTextWrapper: {
      width: '288px',
      margin: '0 auto',
    },
    titleText: {
      color: '#fff',
      margin: `0 auto ${props.userType === UserType.Guide ? 16 : 8}px`,
      fontWeight: 'bold',
      lineHeight: 1,
      fontSize: '22px',
    },
    virtualTour: {
      marginLeft: '8px',
      verticalAlign: 'super',
    },
    forGuide: {
      marginTop: '8px',
      fontFamily: 'Abril Fatface, cursive',
      fontStyle: 'italic',
      fontSize: '18px',
      color: '#666',
    },
  };

  const description = css({
    margin: props.userType === UserType.Guide ? '40px 0 0 0' : '0',
    textAlign: 'justify',
    textAlignLast: 'center',
    fontWeight: 'normal',
    hyphens: 'auto',
    lineHeight: 1.4,
    fontSize: '14px',
    color: props.userType === UserType.Guide ? '#fff' : '#424242',
    '@media(max-width: 959px)': {
      width: '288px',
      fontWeight: 'normal',
    },
  });

  return (
    <div style={style.titleTextWrapper}>
      <h2 style={style.titleText}>
        <a
          href="https://stroly.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={style.link}>
          <img
            src={`/images/stroly.${
              props.userType === UserType.Guide ? 'svg' : 'png'
            }`}
            alt="Stroly"
            width="56"
            height="33"
          />
        </a>
        {props.userType === UserType.Guide && (
          <>
            <img
              src="/images/vtour.svg"
              alt="Virtual Tour"
              width="112"
              height="16"
              style={style.virtualTour}
            />
            <br />
            <span style={style.forGuide}>for Guide</span>
          </>
        )}
      </h2>

      {/* 説明文 */}
      <h3 lang={'en'} css={description}>
        {props.children}
      </h3>
    </div>
  );
});

export default ConnectionTitle;
