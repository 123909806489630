import * as L from 'leaflet';
import Stroly from 'stroly-js';

import { CommentMessage } from '../domains/ws/message.model';
import { Character } from '../domains/user/character';
import { LatLng, ActiveUser } from '../domains/map/map.model';

import { CharacterMarkerFactory } from '../domains/marker/character/character-marker-factory';
import { CharacterMarkers } from '../domains/marker/character/character-markers';

export class CharacterMarkerService {
  private readonly centralMarkerFactory: CharacterMarkerFactory;
  private readonly centralMarkers: CharacterMarkers = new CharacterMarkers();
  private readonly centralMarkersGroup = new L.LayerGroup();
  private timers = new Map<number, NodeJS.Timeout>();

  constructor(private stroly: Stroly, private myToken: number) {
    this.centralMarkersGroup.addTo(stroly.map);
    this.centralMarkerFactory = new CharacterMarkerFactory();
  }

  create(token: number, character: Character) {
    const centralMarker = this.centralMarkers.get(token);

    if (centralMarker) {
      return;
    }
    this.putOnMap(token, this.stroly.map.getCenter(), character);
    this.fireCentralMove(token);
  }

  move(activeUser: ActiveUser) {
    const centralMarker = this.centralMarkers.get(activeUser.token);

    if (centralMarker) {
      centralMarker.setLatLng([activeUser.lat, activeUser.lng]);
      return;
    }

    this.putOnMap(
      activeUser.token,
      { lat: activeUser.lat, lng: activeUser.lng },
      new Character(activeUser.name, activeUser.userType),
    );
  }

  panTo(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.panTo(centralMarker.getLatLng());
    }
  }

  remove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.removeLayer(centralMarker);
      this.centralMarkers.delete(token);
      this.centralMarkersGroup.removeLayer(centralMarker);
    }
  }

  setComment(message: CommentMessage) {
    const timer = this.timers.get(message.token);
    if (timer) {
      clearInterval(timer);
    }
    const centralMarker = this.centralMarkers.get(message.token);

    if (centralMarker) {
      const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
        message.userType,
      );

      centralMarker.setIcon(
        CentralMarker.createIcon(
          new Character(message.name, message.userType),
          message.comment,
        ),
      );

      this.timers.set(
        message.token,
        setInterval(() => {
          centralMarker.setIcon(
            CentralMarker.createIcon(
              new Character(message.name, message.userType),
            ),
          );

          const timer = this.timers.get(message.token);
          if (timer) {
            clearInterval(timer);
          }
        }, 8000),
      );
    }
  }

  fireCentralMove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      const center = this.stroly.map.getCenter();
      centralMarker.setLatLng(center);
      this.stroly.map.fire('centralmove', center);
    }
  }

  private putOnMap(token: number, latlng: LatLng, character: Character) {
    const zoomSize = this.stroly.map.getZoom();
    const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
      character.getUserType(),
    );
    const newCentralMarker = new CentralMarker(
      this.stroly.L,
      latlng,
      character,
      token === this.myToken,
      zoomSize,
    ).marker.addEventListener('contextmenu', () => {
      this.stroly.map.fire('deportation', { token });
    });

    this.centralMarkersGroup.addLayer(newCentralMarker);
    this.centralMarkers.set(token, newCentralMarker);
  }
}
