import React, { useRef } from 'react';
import { History } from 'history';

import { UserType } from '../../../domains/user/user.model';
import { StateContext, DispatchContext } from '../../../core/contexts';
import {
  updateUser,
  updateIsLoading,
  updateIsLoggedIn,
} from '../../../core/reducer';
import { useAvailabilityService } from '../../../hooks/useAvailabilityService';
import { ConnectionsRepository } from '../../../repositories/connections.repository';

type Props = {
  history: History;
  protocol: string;
  limit: number;
  isVtour: boolean;
};

export const useConnectionService = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);

  const availabilityService = useAvailabilityService(
    props.history,
    dispatch,
    props.protocol,
    props.limit,
  );
  const checkVtouravailability = (message: string) => {
    availabilityService.checkIsAfterTour(message);
    availabilityService.checkStartDateAndParticipants();
  };

  const [isDisabled, setIsDisabled] = React.useState(true);

  async function dispatchUpdateUserToBrowse() {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: 'browse mode',
    });

    if (props.isVtour) {
      await availabilityService.checkInBrowseMode();
    }

    dispatch(updateUser({ type: UserType.Browse }));
  }

  const connectionsRepository = useRef(new ConnectionsRepository());
  const login = async () => {
    if (!props.isVtour || state.user.type === UserType.Guide) {
      dispatch(updateIsLoggedIn(true));
      return;
    }

    const isError = await availabilityService.checkStartDateAndParticipants();
    if (isError) {
      return;
    }

    dispatch(updateIsLoading(true));
    connectionsRepository.current
      .post(props.protocol, String(state.user.token))
      .then(() => {
        dispatch(updateIsLoading(false));
        dispatch(updateIsLoggedIn(true));
      })
      .catch((error: Error) => {
        dispatch(updateIsLoading(false));
        props.history.push(
          `/error/${props.protocol}`,
          props.history.location.pathname,
        );
      });
  };

  const [nicknameError, setNicknameError] = React.useState(false);
  const checkInputedName = (name: string) => {
    if (state.user.type === UserType.Guide) {
      setIsDisabled(name.length > 0 ? false : true);
      return name;
    }

    const regex = /[^a-z]/g;
    const newName = name.toLowerCase().replace(regex, '');
    setNicknameError(name.match(regex) !== null);
    setIsDisabled(newName.length > 0 ? false : true);
    return newName;
  };

  return {
    state,
    isDisabled,
    nicknameError,
    availabilityInfo: {
      isBeforeTour: availabilityService.isBeforeTour,
      isFull: availabilityService.isFull,
      startDate: availabilityService.startDate,
      participants: availabilityService.participants,
    },
    dispatch,
    login,
    dispatchUpdateUserToBrowse,
    checkVtouravailability,
    setIsDisabled,
    checkInputedName,
  };
};
