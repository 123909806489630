import * as L from 'leaflet';

import { LatLng } from '../map/map.model';

export class PointMarker extends L.Marker {
  static createIcon(color: string) {
    const markerHtmlStyles1 = `
      position: absolute;
      left: -12px;
      top: -12px;
      border-radius: 50%;
      border: 8px solid ${color};
      width: 8px;
      height: 8px;
    `;
    const markerHtmlStyles2 = `
      position: absolute;
      bottom: -30px;
      left: -6px;
      border: 10px solid transparent;
      border-top: 17px solid ${color};
    `;

    return L.divIcon({
      className: 'comment-marker',
      iconAnchor: [0, 24],
      html: `
        <span style="${markerHtmlStyles1}" />
        <span style="${markerHtmlStyles2}" />
      `,
    });
  }

  constructor(latlng: LatLng, color: string) {
    super([latlng.lat, latlng.lng], {
      icon: PointMarker.createIcon(color),
      draggable: false,
    });
  }
}
