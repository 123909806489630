import React from 'react';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  snsName: 'Twitter' | 'Facebook' | 'LINE';
  url: string;
};

const ShareLink = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    img: {
      verticalAlign: 'middle',
    },
    span: {
      marginLeft: '16px',
      fontSize: '15px',
      verticalAlign: 'middle',
    },
  };

  const link = css({
    display: 'block',
    color: '#333',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  });

  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer" css={link}>
      <img
        src={`/images/icons/${props.snsName.toLowerCase()}${
          props.snsName !== 'LINE' ? '.svg' : '.png'
        }`}
        alt={props.snsName}
        width="32"
        height="32"
        style={style.img}
      />
      <span style={style.span}>{props.snsName}</span>
    </a>
  );
});

export default ShareLink;
