import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

import VtourUrlText from './VtourUrlText';

type Props = {
  vtourUrl: string;
  token: number | undefined;
  openSnakbar: boolean;
  userUrlRef: React.RefObject<HTMLTextAreaElement>;
  guideUrlRef: React.RefObject<HTMLTextAreaElement>;
  handleButtonClick: () => void;
  handleUrlCopy: (target: string) => void;
  handleCloseSnakbar: (event: React.SyntheticEvent, reason: string) => void;
};

const VtourUrl = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {},
    snackbarInner: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  // Safari対応のため1階層上の要素からStyle適用
  const buttonStyle = css({
    ' > button': {
      margin: '0 0 24px',
      padding: '4px 16px',
      width: '264px',
      height: '40px',
      fontSize: '12px',
      fontWeight: 500,
      borderRadius: '40px',
      border: 0,
      backgroundColor: '#feb100',
      color: '#000',
      ':disabled': {
        cursor: 'not-allowed',
        backgroundColor: '#ffe8b3!important',
        color: '#b3b3b3',
      },
      ':hover': {
        backgroundColor: '#eba910',
      },
    },
  });

  const snackbar = css({
    padding: '0 8px',
    height: '56px',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#333',
    borderRadius: '8px',
    boxShadow:
      '0px 8px 16px -2px rgba(10,10,10,0.1), 0px 0px 10px 1px rgba(0, 0, 0, .6)',
    '.MuiPaper-elevation6': {
      boxShadow: 'none',
    },
    '.MuiSnackbarContent-root': {
      justifyContent: 'center',
      backgroundColor: '#333',
    },
  });

  return (
    <div style={style.div} css={buttonStyle}>
      <Button
        variant="contained"
        onClick={props.handleButtonClick}
        disabled={!!props.vtourUrl}>
        <FormattedMessage id="VtourUrl.Publish" />
      </Button>
      <VtourUrlText
        vtourUrl={props.vtourUrl}
        token={props.token}
        isGuide={false}
        handleUrlCopy={props.handleUrlCopy}
        textareaRef={props.userUrlRef}
      />
      <VtourUrlText
        vtourUrl={props.vtourUrl}
        token={props.token}
        isGuide={true}
        handleUrlCopy={props.handleUrlCopy}
        textareaRef={props.guideUrlRef}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.openSnakbar}
        autoHideDuration={3000}
        onClose={props.handleCloseSnakbar}
        css={snackbar}
        message={
          <div style={style.snackbarInner}>
            <img
              src="/images/icons/check.svg"
              alt="success"
              width="20"
              height="20"
              style={{ marginRight: '8px' }}
            />
            <FormattedMessage id="VtourUrl.CopyUrl" />
          </div>
        }
        TransitionComponent={Fade}
      />
    </div>
  );
});

export default VtourUrl;
