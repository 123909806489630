import React from 'react';
import { FormattedMessage } from 'react-intl';

import { User } from '../../../../domains/user/user.model';
import { VtourOptions } from '../../../../types/vmap.type';

import ConnectionTitle from '../shared/ConnectionTitle';
import ConnectionParticipants from '../shared/ConnectionParticipants';
import ConnectionName from '../shared/ConnectionName';
import ConnectionStartDate from '../shared/ConnectionStartDate';
import ConnectionButton from '../shared/ConnectionButton';
import GuideConnectionGuideImage from './ConnectionGuideImage';
import ConnectionGuideSetting from './ConnectionGuideSetting';

type Props = {
  isDisabled: boolean;
  user: User;
  vtourOptions: VtourOptions;
  title: string | undefined;
  name: string;
  availabilityInfo: {
    isBeforeTour: boolean;
    isFull: boolean;
    startDate: moment.Moment | undefined;
    participants: number;
  };
  nicknameError: boolean;
  handleNameChange: (name: string) => void;
  enterTheMap: () => void;
};

const ConnectionGuideModal = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    modal: {
      margin: '16px',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '8px',
      color: '#fff',
      overflowY: 'scroll',
      overflowX: 'hidden',
      width: '100%',
      maxWidth: '720px',
    },
  };

  return (
    <div style={style.modal}>
      {/* タイトル */}
      <ConnectionTitle userType={props.user.type}>
        <FormattedMessage
          id="ConnectionModal.DescriptionForGuide"
          values={{ title: props.title }}
        />
      </ConnectionTitle>

      {/* キャラクター設定フォーム */}
      <ConnectionGuideSetting>
        <GuideConnectionGuideImage />
        <ConnectionName
          userType={props.user.type}
          name={props.name}
          isVtour={props.vtourOptions.isVtour}
          isError={props.nicknameError}
          handleChange={props.handleNameChange}
        />
        <ConnectionStartDate
          vtourOptions={props.vtourOptions}
          startDate={props.availabilityInfo.startDate}
          userType={props.user.type}
        />
        <ConnectionParticipants
          participants={props.availabilityInfo.participants}
          limit={props.vtourOptions?.limit}
          userType={props.user.type}
        />

        <ConnectionButton
          isDisabled={props.isDisabled}
          isVtour={props.vtourOptions.isVtour}
          handleClick={props.enterTheMap}
        />
      </ConnectionGuideSetting>
    </div>
  );
});

export default ConnectionGuideModal;
