import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

type Props = {
  isVtour: boolean;
  isFull: boolean;
  handleBrowseModeClick: () => void;
};

const ConnectionBrowse = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      fontSize: '12px',
      color: '#666',
      paddingTop: '12px',
      margin: '0',
    },
    span: {
      cursor: 'pointer',
    },
    button: {
      marginTop: '8px',
      marginBottom: '16px',
      width: '240px',
      lineHeight: '32px',
      borderRadius: '25px',
      fontSize: '14px',
      fontWeight: 'bold',
      backgroundColor: '#eab540',
      color: '#000',
    },
  };

  const i18nId = props.isVtour
    ? 'ConnectionBrowse.BrowseModeForVtour'
    : 'ConnectionBrowse.BrowseMode';

  const button = props.isFull ? (
    <Button
      style={style.button}
      variant="contained"
      onClick={props.handleBrowseModeClick}>
      <FormattedMessage id="ConnectionBrowse.BrowseModeForVtour" />
    </Button>
  ) : (
    <p style={style.p}>
      <span onClick={props.handleBrowseModeClick} style={style.span}>
        <FormattedMessage id={i18nId} />
      </span>
    </p>
  );

  return button;
});

export default ConnectionBrowse;
