import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { ja, en } from '../i18n/error';
import { useLang } from '../../hooks/useLang';

import Address from './Address';

const InvalidUrlError = React.memo(() => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      margin: '40px auto',
      textAlign: 'center',
    },
  };

  const lang = useLang({ ja, en });

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <div style={style.div}>
        <p>
          <FormattedMessage id="InvalidUrlError.Message" />
        </p>
        <Address type={'text'} />
      </div>
    </IntlProvider>
  );
});

export default InvalidUrlError;
