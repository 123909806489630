import * as L from 'leaflet';

import { GuideMarker } from './guide-marker';
import { UserMarker } from './user-marker';
import { UserType } from '../../user/user.model';

export type CentralMarker = {
  readonly marker: L.Marker;
};

export class CharacterMarkerFactory {
  private readonly types: Map<UserType, CentralMarker | any> = new Map();

  constructor() {
    this.types.set(UserType.User, UserMarker);
    this.types.set(UserType.Guide, GuideMarker);
  }

  centralMarkerByName(type: UserType) {
    return this.types.get(type);
  }
}
