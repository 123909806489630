import React from 'react';
import { Location } from 'history';
import { IntlProvider, FormattedMessage } from 'react-intl';

import { ja, en } from '../i18n/error';
import { useLang } from '../../hooks/useLang';

type Props = {
  location: Location;
};

const Error = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      width: '100%',
      height: '100%',
    },
    p: {
      textAlign: 'center',
      width: '100%',
    },
  };

  const url = props.location.state as any;

  React.useEffect(() => {
    window.history.pushState(null, null as any, null);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null as any, null);
    });
    gtag('config', 'UA-163574484-1', {
      page_title: 'error',
      page_path: window.location.pathname,
    });

    gtag('event', 'exception', {
      description: 'system error',
      fatal: true,
    });
  }, []);

  const lang = useLang({ ja, en });

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <div style={style.div}>
        <p style={style.p}>
          <FormattedMessage id="Error.Message" />
        </p>
        <p style={style.p}>
          <a href={url}>
            <FormattedMessage id="Error.Back" />
          </a>
        </p>
        <p style={style.p}>
          <a href="https://stroly.com">
            <FormattedMessage id="Error.BackToStroly" />
          </a>
        </p>
      </div>
    </IntlProvider>
  );
});

export default Error;
