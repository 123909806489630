import React, { useCallback, memo, useState, useEffect } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { History } from 'history';

import { updateIsDisabled, updateIsSharing } from '../../../core/reducer';
import { useWebSocketSendService } from '../../../hooks/useWebSocketSendService';
import { useWebSocketService } from '../../../hooks/useWebSocketService';
import { useLogoutService } from '../hooks/useLogoutService';
import { useMapEventListener } from '../../../hooks/useMapEventListener';
import { useMapContainerService } from '../hooks/useMapContaierService';

import { MapService } from '../../../domains/map';
import { VtourOptions } from '../../../types/vmap.type';
import { UserType } from '../../../domains/user/user.model';
import { Character } from '../../../domains/user/character';

import StrolyMap from '../components/map/StrolyMap';
import UserList from '../components/map/UserList';
import CommentContainer from './CommentContainer';
import CentralIcon from '../components/map/CentralIcon';
import AboutButton from '../components/map/AboutButton';
import ExitButton from '../components/map/ExitButton';
import LocateButton from '../components/map/LocateButton';
import Address from '../../../core/components/Address';
import Dialog from '../../../core/components/Dialog';
import About from '../components/map/About';
import Snackbar from '../../../core/components/Snackbar';
import DisconnectionDialog from '../components/map/DisconnectionDialog';
import InfomationButton from '../components/map/InfomationButton';
import Infomation from '../components/map/Infomation';
import Share from '../components/map/Share';

type Props = {
  intl: IntlShape;
  history: History;
  mapService: MapService;
  vtourOptions: VtourOptions;
  mapID: string;
  character: Character;
  protocol: string;
};

const MapContainer = memo((props: Props) => {
  const service = useMapContainerService();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [exitUser, setExitUser] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const webSocketService = useWebSocketService({
    intl: props.intl,
    room: props.protocol,
    character: props.character,
    mapService: props.mapService,
    isLoggedIn: service.state.isLoggedIn,
    isBrowseMode: service.isBrowseMode.current,
    isShareMode: service.isShareMode.current,
    history: props.history,
    setIsSnackbarOpen,
    setExitUser,
    setComment,
  });

  useEffect(() => {
    if (service.state.isLoggedIn) {
      webSocketService.init();
    } else {
      // 退出時の処理
    }
    // eslint-disable-next-line
  }, [service.state.isLoggedIn]);

  const webSocketSendService = useWebSocketSendService(
    webSocketService.getRepository(),
    props.protocol,
    {
      token: service.state.user.token,
      color: service.state.user.color,
      character: props.character,
    },
  );

  useEffect(() => {
    if (!webSocketService.getRepository().get() && service.state.isLoggedIn) {
      webSocketService.getRepository().connect();
    }
  }, [service.state.isLoggedIn, webSocketService]);

  const initStrolyMap = useCallback((mapRef: HTMLElement) => {
    props.mapService.initStrolyMap(
      mapRef,
      service.state.user.token,
      props.mapID,
    );
    // eslint-disable-next-line
  }, []);

  function moveToMarker(token: number) {
    props.mapService.panToCentralMarker(token);
    props.mapService.panToLocationMarker(token);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'UserList',
    });
  }

  useEffect(() => {
    service.setUrl(props.history.location.pathname);
    // eslint-disable-next-line
  }, [props.history.location.pathname]);

  function toggleShareMode() {
    service.dispatch(updateIsDisabled(true));
    if (service.isShareMode.current) {
      props.mapService.panToLocationMarker(service.state.user.token);
      setTimeout(() => {
        props.mapService.stopGettingCurrentLocation();
        props.mapService.createCentralMarker(
          service.state.user.token,
          props.character,
        );
      }, 500);
      service.dispatch(updateIsSharing(!service.state.isSharing));
    } else {
      const message = props.intl.formatMessage({
        id: 'VmapContainer.LocateButton.ConfrimMessage',
      });
      const isOK = window.confirm(message);
      if (!isOK) {
        service.dispatch(updateIsDisabled(false));
        return;
      }
      props.mapService.getCurrentLocation();
    }
    gtag('event', 'click', {
      event_category: 'button',
      event_label: service.isShareMode.current
        ? 'Stop Sharing'
        : 'Share Your Location',
    });
  }

  /**
   * 画面やタブを閉じる直前で実行したい処理
   */
  const logoutService = useLogoutService({
    intl: props.intl,
    isVtour: props.vtourOptions.isVtour,
    room: props.protocol,
    character: props.character,
    mapService: props.mapService,
    webSocketSendService,
    webSocketService,
    setIsSnackbarOpen,
    setExitUser,
  });

  /**
   * Strolyマップに関するイベントハンドリング処理
   */
  useMapEventListener({
    intl: props.intl,
    protocol: props.protocol,
    character: props.character,
    mapID: props.mapID,
    mapService: props.mapService,
    isLoggedIn: service.state.isLoggedIn,
    webSocketSendService,
    sendDatatoWS: webSocketService.getRepository().send,
  });

  return (
    <StrolyMap handleInit={initStrolyMap}>
      <>
        <CommentContainer
          webSocketRepository={webSocketService.getRepository()}
          webSocketSendService={webSocketSendService}
          isConnected={webSocketService.isConnected}
          isMobileDisplay={service.isMobileDisplay}
          vtourOptions={props.vtourOptions}
          isShareMode={service.isShareMode.current}
          mapService={props.mapService}
          comment={comment}
          setComment={setComment}
        />
        <UserList
          user={service.state.user}
          activeUsers={service.state.activeUsers}
          isUserListOpen={service.isUserListOpen}
          setIsUserListOpen={service.setIsUserListOpen}
          handleCharacterClick={moveToMarker}
        />
        {service.state.user.type === UserType.Guide ||
        (service.state.user.type !== UserType.Browse &&
          props.vtourOptions.isLocateEnabled) ? (
          <LocateButton
            isDisabled={service.state.isDisabled}
            isSharing={service.state.isSharing}
            handleButtonClick={toggleShareMode}
          />
        ) : null}
        <AboutButton openAboutDialog={service.openAboutDialog} />
        <Dialog
          isDialogOpen={service.isAboutDialogOpen}
          setIsDialogOpen={service.setIsAboutDialogOpen}>
          <About vtourOptions={props.vtourOptions} />
        </Dialog>
        <ExitButton handleButtonClick={logoutService.openExitDialog} />
        <Address color="#fff" position="responsive" />
        <InfomationButton handleButtonClick={service.openInfomationDialog} />
        {props.mapService.isMobile === false &&
          (props.vtourOptions.isSnsShareEnabled ||
            service.state.user.type === UserType.Guide) && (
            <Share
              isPopperOpen={service.isSharePopperOpen}
              popperAnchorEl={service.sharePopperAnchor}
              handleCloseClick={service.setIsSharePopperOpen}
              handleAnchorClick={service.setSharePopperOption}
              getShareUrl={service.getShareUrl}
            />
          )}
        <Dialog
          isDialogOpen={service.isInfomationDialogOpen}
          setIsDialogOpen={service.setIsInfomationDialogOpen}>
          <Infomation />
        </Dialog>
        <Snackbar
          isSnackbarOpen={isSnackbarOpen}
          setIsSnackbarOpen={setIsSnackbarOpen}>
          <span>{exitUser} left the map.</span>
        </Snackbar>
      </>
      {service.state.user.type === UserType.Browse && <CentralIcon />}
      {logoutService.disconnectionDialogOpen && (
        <DisconnectionDialog
          isVtour={props.vtourOptions.isVtour}
          handleExitButtonClick={logoutService.exit}
          handleCancelButtonClick={logoutService.cancelExit}
        />
      )}
    </StrolyMap>
  );
});

export default injectIntl(MapContainer);
