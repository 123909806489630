import { useEffect, useCallback, useContext, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Character } from '../../../domains/user/character';
import { Color } from '../../../domains/user/color';
import { StateContext, DispatchContext } from '../../../core/contexts';
import { MapService } from '../../../domains/map';
import { updateUser } from '../../../core/reducer';
import { VTourIconColor } from '../../../core/constants';
import { VtourOptions } from '../../../types/vmap.type';
import { UserType } from '../../../domains/user/user.model';
import * as Constants from '../../../core/constants';

interface Props extends RouteComponentProps {}

export const useVmapService = (props: Props) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const mapServiceRef = useRef<MapService>(null);
  useEffect(() => {
    if (mapServiceRef.current === null) {
      (mapServiceRef as any).current = new MapService(dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    if (state?.user.token) {
      gtag('set', { user_id: state?.user.token });
    }
  }, [state?.user.token]);

  const [mapID, setMapID] = useState<string>();
  const [protocol, setProtocol] = useState<string>();
  const [vtourOptions, setVtourOptions] = useState<VtourOptions>({
    isVtour: false,
    guideToken: NaN,
    limit: 20,
    isCommentEnabled: true,
    isEmojiEnabled: true,
    isCommentListEnabled: true,
    isLocateEnabled: true,
    isSnsShareEnabled: true,
  });

  const initVtour = useCallback(
    (path: string[]) => {
      let param;
      try {
        param = atob(path[2]).split(',');
      } catch {
        throw new Error('Forbidden error occurred.');
      }

      const [
        guideToken,
        mapID,
        limit,
        isCommentEnabled,
        isEmojiEnabled,
        isCommentListEnabled,
        isLocateEnabled,
        isSnsShareEnabled,
      ] = param;

      if (path[3] && path[3] !== guideToken) {
        throw new Error('Forbidden error occurred.');
      }

      if (path[3] && path[3] === guideToken) {
        dispatch(
          updateUser({
            type: UserType.Guide,
            token: Number(path[3]),
          }),
        );
      }

      setMapID(mapID);
      setProtocol(guideToken + mapID);

      const vtourOptions = {
        isVtour: true,
        guideToken: Number(guideToken),
        limit: Number(limit),
        isCommentEnabled: !!Number(isCommentEnabled),
        isEmojiEnabled: !!Number(isEmojiEnabled),
        isCommentListEnabled: !!Number(isCommentListEnabled),
        isLocateEnabled: !!Number(isLocateEnabled),
        isSnsShareEnabled: !!Number(isSnsShareEnabled),
      } as VtourOptions;
      setVtourOptions(vtourOptions);
    },
    [dispatch],
  );

  const initVmap = useCallback(() => {
    const mapID = (props.match.params as any).mapid || Constants.DefaultMapId;
    const room = props.location.search.match(/room=([^&#]*)/);
    const protocol = room ? room[1] + mapID : state?.user.token + mapID;
    setMapID(mapID);
    setProtocol(protocol);
  }, [props.match.params, props.location.search, state?.user.token]);

  useEffect(() => {
    const path = props.location.pathname.split('/');

    if (path[1] === 'vtour') {
      initVtour(path);
    } else {
      initVmap();
    }
    // eslint-disable-next-line
  }, []);

  const [name, setName] = useState<string>('');
  const [userType, setUserType] = useState<UserType>(UserType.User);
  const [character, setCharacter] = useState<Character>(
    new Character('', UserType.User),
  );

  const handleNameChange = useCallback(
    (name: string) => {
      setName(name);
      setCharacter(new Character(name, userType));

      const iconColor =
        userType === UserType.Guide
          ? '#000000'
          : VTourIconColor[name.substr(0, 1)];

      dispatch(
        updateUser({
          color: new Color(iconColor).getColor(),
        }),
      );
    },
    [userType, dispatch],
  );

  const handleUserTypeSelected = useCallback(
    (userType: UserType) => {
      setUserType(userType);
      setCharacter(new Character(name, userType));
    },
    [name],
  );

  return {
    mapID,
    protocol,
    vtourOptions,
    name,
    character,
    state,
    mapService: mapServiceRef.current,
    dispatch,
    handleNameChange,
    handleUserTypeSelected,
  };
};
