import React from 'react';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

import ShareLink from './ShareLink';

type Props = {
  isPopperOpen: boolean;
  popperAnchorEl: HTMLButtonElement | null;
  handleCloseClick: React.Dispatch<React.SetStateAction<boolean>>;
  handleAnchorClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  getShareUrl: (snsName: 'Twitter' | 'Facebook' | 'LINE') => string;
};

const Share = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      position: 'absolute',
      top: '80px',
      right: '8px',
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'block',
      border: 0,
      backgroundColor: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
      width: '48px',
      height: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    img: {
      verticalAlign: 'middle',
    },
    close: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      fontSize: '32px',
      cursor: 'pointer',
    },
    ul: {
      marginTop: 0,
      paddingLeft: 0,
      width: '160px',
      listStyle: 'none',
    },
  };

  const div = css({
    position: 'relative',
    padding: '32px 64px',
    marginRight: '24px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    '::before': {
      content: `""`,
      position: 'absolute',
      top: '16px',
      left: '100%',
      border: '16px solid transparent',
      borderLeft: '16px solid #fff',
    },
  });

  const li = css({
    li: {
      marginTop: '24px',
      ':first-of-type': {
        marginTop: '0',
      },
    },
  });

  // eslint-disable-next-line
  const shareLink = ['Twitter', 'Facebook', 'LINE'].map((name) => {
    if (name === 'Twitter' || name === 'Facebook' || name === 'LINE') {
      return (
        <li key={`share-${name}`}>
          <ShareLink snsName={name} url={props.getShareUrl(name)} />
        </li>
      );
    }
  });

  return (
    <>
      <button style={style.button} onClick={props.handleAnchorClick}>
        <img
          src="/images/icons/share.svg"
          alt="share"
          width="17"
          height="19"
          style={style.img}
        />
      </button>
      <Popper
        open={props.isPopperOpen}
        anchorEl={props.popperAnchorEl}
        placement={'left-start'}>
        <div css={div}>
          <CloseIcon
            onClick={() => props.handleCloseClick(!props.isPopperOpen)}
            style={style.close}
          />
          <ul style={style.ul} css={li}>
            {shareLink}
          </ul>
        </div>
      </Popper>
    </>
  );
});

export default Share;
