import * as L from 'leaflet';

import { LatLng } from '../../map/map.model';
import { Character } from '../../user/character';

export class UserMarker {
  readonly marker: L.Marker;

  constructor(
    L: any,
    latlng: LatLng,
    character: Character,
    isMe: boolean = false,
  ) {
    this.marker = new L.marker([latlng.lat, latlng.lng], {
      icon: UserMarker.createIcon(character),
      draggable: false,
      // riseOnHover: true,
      // riseOffset: -2000,
      zIndexOffset: isMe ? 500 : 0,
    });
  }

  static getIsEmoji(message: string | undefined): boolean {
    if (!message) {
      return false;
    }
    return /^(👍|❤️|❗️)$/.test(message);
  }

  static getClassName() {
    try {
      return L.Browser.mobileWebkit &&
        typeof DeviceOrientationEvent.requestPermission !== 'function'
        ? ''
        : `marker-icon`;
    } catch {
      return '';
    }
  }

  static createIcon(character: Character, message?: string): L.DivIcon {
    const MerkerWidth = 160;
    const iconSize = 32;

    // const a = [(26 / 3) * 5, (100 / 3) * 5] as any;
    // let iconAnchor = [(a[0] / 5) * 3, (a[1] / 5) * 3] as any;
    let iconAnchor = [MerkerWidth / 2, iconSize] as any;

    // zoomサイズに合わせてアイコンのサイズを変更する処理
    // とりあえず挙動がおかしいということなので一時的にコメントアウトしておく
    // あとで調整する
    // if (zoomSize >= 5) {
    //   iconAnchor = [(a[0] / 5) * 5, (a[1] / 5) * 5];
    // }
    // if (zoomSize === 4) {
    //   iconAnchor = [(a[0] / 5) * 4, (a[1] / 5) * 4];
    // }
    // if (zoomSize === 3) {
    //   iconAnchor = [(a[0] / 5) * 3, (a[1] / 5) * 3];
    // }
    // if (zoomSize === 2) {
    //   iconAnchor = [(a[0] / 5) * 2, (a[1] / 5) * 2];
    // }
    // if (zoomSize <= 1) {
    //   iconAnchor = [(a[0] / 5) * 1, (a[1] / 5) * 1];
    // }

    const style = {
      marker: `
        position: relative;
        margin: 0;
        padding: 0;
        width: ${MerkerWidth}px;
        text-align: center;
      `,
      popup: `
        position: fixed;
        bottom: 22px;
        width: ${MerkerWidth}px;
        text-aling: center;
        filter: drop-shadow(0px 2px 4px #424242);
      `,
      comment: `
        box-sizing: border-box;
        display: inline-block;
        text-align: left;
        border-radius: 8px;
        background-color: #fff;
        color: #000;
        padding: 4px 8px;
        opacity: 1;
        line-height: 1.2;
        maxWidth: ${MerkerWidth}px;
        word-wrap: break-word;
        hyphens: auto;
        font-size: 14px;
      `,
      message: `
        font-size: 26px;
      `,
      bubble: `
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -6px;
        border: 6px solid transparent;
        opacity: 1;
        border-top: 6px solid #fff;
      `,
      icon: `
        position: relative;
        display: block;
        margin: 0 auto;
        width: ${iconSize}px;
        height: ${iconSize}px;
      `,
      name: `
        font-size: 12px;
        box-sizing: border-box;
        display: inline-block;
        font-weight: bold;
        margin: 0;
        padding: 4px 8px;
        line-height: 1;
        text-align: left;
        color: #333;
        background-color: rgba(255, 255, 255, .4)
      `,
    };

    const comment = UserMarker.getIsEmoji(message)
      ? `<span style="${style.message}">${message}</span>`
      : UserMarker.encodeMessage(message);

    const popup = message
      ? `<div style="${style.popup}">
          <div style="${style.comment}" lang="en">
            <b>${comment}</b>
          </div>
          <div style="${style.bubble}"></div>
        </div>`
      : '';

    const className = UserMarker.getClassName();

    return L.divIcon({
      className,
      iconAnchor,
      popupAnchor: [8, -104],
      html: `
        <div style="${style.marker}">
          ${popup}
          <img
            src="/images/characters/${character.getInitial()}.svg"
            width="${iconSize}"
            height="${iconSize}"
            alt="icon"
            style="${style.icon}">
          <span style="${style.name}">
            ${UserMarker.encodeMessage(character.getName())}
          </span>
      `,
    });
  }

  static encodeMessage(string: String | undefined) {
    if (!string) {
      return string;
    }
    return string
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
  }
}
