import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  children?: React.ReactChild[];
};

const VtourModal = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    modal: {
      margin: '16px auto',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '320px',
      width: '100%',
      color: '#ccc',
      padding: '16px 0 0',
      maxHeight: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    title: {
      margin: 0,
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1,
    },
    virtualTour: {
      marginLeft: '8px',
      verticalAlign: 'baseline',
    },
    caption: {
      margin: '0 0 0 8px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: 1.3,
      color: '#999',
      textAlign: 'left',
    },
    border: {
      margin: '56px auto 32px',
      width: '344px',
    },
  };
  return (
    <div style={style.modal}>
      <h2 style={style.title}>
        <img
          src="/images/stroly.svg"
          alt="Stroly"
          width="56"
          height="33"
          style={{ verticalAlign: 'middle' }}
        />
        <img
          src="/images/vtour.svg"
          alt="Virtual Tour"
          width="112"
          height="16"
          style={style.virtualTour}
        />
      </h2>
      <div style={style.border}>
        <h3 style={style.caption}>
          <FormattedMessage id="VtourModal.GetStarted" />
        </h3>
        {props.children}
      </div>
    </div>
  );
});

export default VtourModal;
