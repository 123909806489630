import React from 'react';

const ConnectionGuideImage = React.memo(() => {
  const style: { [key: string]: React.CSSProperties } = {
    icon: {
      marginRight: '8px',
      width: '32px',
      height: '32px',
      backgroundImage: 'url(/images/characters/guide.svg)',
      backgroundColor: 'transparent',
      backgroundSize: 'cover',
    },
  };

  return <div style={style.icon}></div>;
});

export default ConnectionGuideImage;
