export const Service = 'Stroly';

export const Title = 'Viewer';

export const TourTitle = 'Vitual Tour';

export const Description =
  'Stroly Viewer where all maps of Stroly.com can be experienced in virtual. Also supports communication with multiple members using the group function.';

export const Thumbnail = `${process.env.REACT_APP_SITE_URL}/ogp.jpg`;

export const IllustmapRootURL = 'https://ods3.illustmap.org';

export const DeportedUserName = '___deported_user___';

export const DefaultMapId = 'Yoshida216Kyoto1928';

export const VTourIconColor: { [key: string]: string } = {
  a: '#e83828',
  b: '#eb6100',
  c: '#f39800',
  d: '#f8b62d',
  e: '#b5b51e',
  f: '#8fc31f',
  g: '#22ac38',
  h: '#00913a',
  i: '#006934',
  j: '#13ae67',
  k: '#00a29a',
  l: '#56bfc4',
  m: '#2ea7e0',
  n: '#118f9d',
  o: '#1684a9',
  p: '#045a99',
  q: '#1e257d',
  r: '#303b95',
  s: '#413b94',
  t: '#612079',
  u: '#6a3a93',
  v: '#983f93',
  w: '#b2348d',
  x: '#c01465',
  y: '#cd196f',
  z: '#e34483',
};
