import React from 'react';

import { StateContext, DispatchContext } from '../core/contexts';
import { ActiveUser } from '../domains/map/map.model';
import { MapService } from '../services/map.service';
import { updateUserList, updateComments } from '../core/reducer';
import { CommentMessage } from '../domains/ws/message.model';

export const useMapUsecase = (mapService: MapService) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const moveAvatarUser = (movedUser: ActiveUser) => {
    mapService.moveCentralMarker(movedUser);
  };

  const moveGpsUser = (movedUser: ActiveUser) => {
    if (
      !mapService.locations[movedUser.token] &&
      movedUser.token === state.user.token
    ) {
      mapService.panTo(movedUser.lat, movedUser.lng);
    }
    mapService.putLocationMarker(movedUser);
  };

  const addUserToUserList = (movedUser: ActiveUser) => {
    const activeUser = mapService.activeUsers[movedUser.token];

    if (
      !activeUser ||
      movedUser.timestamp.diff(activeUser?.timestamp) > 1000 * 60
    ) {
      mapService.activeUsers = {
        ...mapService.activeUsers,
        [movedUser.token]: movedUser,
      };
      dispatch(updateUserList(mapService.activeUsers));
    }
  };

  const hideAvatarUser = (movedUser: ActiveUser) => {
    mapService.removeCentralMarker(movedUser.token);
  };

  const hideGpsUser = (movedUser: ActiveUser) => {
    mapService.removeLocationMarker(movedUser.token);
  };

  const removeUser = (movedUser: ActiveUser) => {
    mapService.removeCentralMarker(movedUser.token);
    mapService.removeLocationMarker(movedUser.token);
    delete mapService.activeUsers[movedUser.token];
    dispatch(updateUserList(mapService.activeUsers));
  };

  const setCommentToUser = (message: CommentMessage) => {
    mapService.setCommentToLocationMarker(message);
    mapService.setCommentToCentralMarker(message);
  };

  const addCommentToCommentList = (comment: CommentMessage) => {
    dispatch(updateComments(comment));
  };

  return {
    moveAvatarUser,
    moveGpsUser,
    addUserToUserList,
    hideAvatarUser,
    hideGpsUser,
    removeUser,
    setCommentToUser,
    addCommentToCommentList,
  };
};
