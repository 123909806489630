import React, { useCallback } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  intl: IntlShape;
  comment: string;
  isDisabled: boolean;
  handleChange(value: string): void;
  handleSendMessage(): void;
};

const CommentSendMessage = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    wrapper: {
      display: 'flex',
    },
    button: {
      boxSizing: 'border-box',
      border: 0,
      marginRight: '-8px',
      padding: '8px',
      background: 'rgba(0, 0, 0, .6)',
    },
    disabled: {
      opacity: '0.2',
      cursor: 'not-allowed',
    },
  };

  const iconButtonStyle =
    !props.comment || props.isDisabled
      ? { ...style.button, ...style.disabled }
      : style.button;

  const inputBase = css({
    '> .MuiInputBase-root': {
      paddingLeft: '8px',
      width: '100%',
      color: '#fff',
    },
  });

  const [isEnabled, setIsEnabled] = React.useState(true);
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) {
        if (isEnabled) {
          props.handleSendMessage();
          setIsEnabled(false);

          setTimeout(() => {
            setIsEnabled(true);
          }, 1000);
        }
      }
    },
    [isEnabled, props],
  );

  const placeholder = props.intl.formatMessage({
    id: 'CommentSendMessage.Placeholder',
  });

  const handleClick = useCallback(() => {
    if (isEnabled && props.comment) {
      props.handleSendMessage();
      setIsEnabled(false);

      setTimeout(() => {
        setIsEnabled(true);
      }, 1000);
    }
  }, [isEnabled, props]);

  return (
    <div style={style.wrapper} css={inputBase}>
      <InputBase
        type="text"
        name="comment"
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'Message' }}
        onChange={(event) => props.handleChange(event.currentTarget.value)}
        onKeyDown={handleKeyDown}
        value={props.comment}
      />
      <IconButton
        aria-label="Send"
        style={iconButtonStyle}
        onClick={handleClick}
        disabled={!props.comment || props.isDisabled}>
        <img src="/images/icons/send.svg" alt="send" width="16" height="16" />
      </IconButton>
    </div>
  );
});

export default injectIntl(CommentSendMessage);
