import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
};

const Dialog = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    close: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      cursor: 'pointer',
    },
  };

  const dialog = css({
    '.MuiDialog-paperWidthSm': {
      borderRadius: '10px',
    },
  });

  const handleClose = () => {
    props.setIsDialogOpen(false);
  };

  return (
    <MuiDialog
      open={props.isDialogOpen}
      onClose={handleClose}
      scroll={'paper'}
      css={dialog}>
      <MuiDialogContent dividers={true}>
        <CloseIcon onClick={handleClose} style={style.close} />
        {props.children}
      </MuiDialogContent>
    </MuiDialog>
  );
};

export default Dialog;
