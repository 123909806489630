import React from 'react';

type Props = {
  handleButtonClick(): void;
};

const InfomationButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      position: 'absolute',
      top: 'calc(50vh - 16px)',
      right: '8px',
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'block',
      padding: 0,
      border: 0,
      backgroundColor: '#eee',
      borderRadius: '50%',
      cursor: 'pointer',
      width: '32px',
      height: '32px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    image: {
      verticalAlign: 'middle',
    },
  };

  return (
    <button onClick={props.handleButtonClick} style={style.button}>
      <img
        src="/images/icons/infomation.svg"
        alt="infomation"
        width="4"
        height="16"
        style={style.image}
      />
    </button>
  );
});

export default InfomationButton;
