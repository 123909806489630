import * as L from 'leaflet';
import Stroly from 'stroly-js';
import React from 'react';

import { ActiveUser, ActiveUsers, LatLng } from '../domains/map/map.model';
import { LandmarkService } from './landmark.service';
import { CharacterMarkerService } from './character-marker.service';
import { LocationMarker } from '../domains/marker/location-marker';
import { PointMarker } from '../domains/marker/point-marker';
import { CommentMessage } from '../domains/ws/message.model';
import { Character } from '../domains/user/character';

import {
  setMetaData,
  updateIsCommentListOpen,
  updateIsLandmarkOpen,
} from '../core/reducer';

declare module 'leaflet' {
  interface LeafletEvent {
    latlng: LatLng;
  }
}

export class MapService {
  stroly!: Stroly;
  strolyMap!: L.Map;
  activeUsers: ActiveUsers = {};
  isMobile = L.Browser.mobile;
  readonly locations: { [token: number]: L.Marker } = {};
  private landmarkService!: LandmarkService;
  private characterMarkerService!: CharacterMarkerService;
  private centerLatLng!: L.LatLng;
  private commentMarker!: L.Marker;

  timer!: NodeJS.Timeout;

  constructor(private dispatch: React.Dispatch<any>) {}

  initStrolyMap(elem: HTMLElement, token: number, mapID: string) {
    this.stroly = new Stroly(elem, mapID, {
      doubleClickZoom: false,
      zoomSnap: 0.1,
      zoomDelta: 0.5,
      zoomControl: false,
      attributionControl: false,
      preferCanvas: true,
    });
    this.strolyMap = this.stroly.map;
    this.characterMarkerService = new CharacterMarkerService(
      this.stroly,
      token,
    );

    this.stroly.renderBaseLayer({
      onReady: () => {
        this.dispatch(setMetaData(this.stroly.getMapMetaData()));
        this.stroly.map.setZoom(5);
        this.centerLatLng = this.getCentralLatLng();
        this.emitCentralMove(token);

        this.strolyMap.on('click', () => {
          this.dispatch(updateIsCommentListOpen(false));
          this.dispatch(updateIsLandmarkOpen(false));
          this.landmarkService.setSelectedLandmarkMarker();
        });

        this.strolyMap.on('moveend', () => {
          clearInterval(this.timer);
          this.characterMarkerService.fireCentralMove(token);

          const xy = this.strolyMap.getCenter();
          const latlng = this.stroly.strolyToLatLng(xy);
          const zoomScale = this.strolyMap.getZoom();
          const date = new Date().toISOString();
          gtag('event', 'move', {
            event_category: 'location',
            event_label: JSON.stringify([
              mapID,
              latlng?.lat,
              latlng?.lng,
              xy.lat,
              xy.lng,
              zoomScale,
              date,
            ]),
          });

          this.emitCentralMove(token);
        });

        // Landmark周りの初期化処理
        this.landmarkService = new LandmarkService(
          mapID,
          this.stroly,
          this.dispatch,
        );
        this.landmarkService.init();
      },
    });
  }

  private emitCentralMove(token: number) {
    this.timer = setInterval(() => {
      this.characterMarkerService.fireCentralMove(token);
    }, 10000);
  }

  setSelectedLandmarkMarker(landmarkId?: number) {
    this.landmarkService.setSelectedLandmarkMarker(landmarkId);
  }

  setLandmarkCategory(category: string) {
    this.landmarkService.displayByCategory(category);
  }

  getCentralLatLng() {
    return this.strolyMap.getCenter();
  }

  getIsInMap(latlng: LatLng) {
    return this.stroly.getIsInMap(latlng);
  }

  createCentralMarker(token: number, character: Character): void {
    this.characterMarkerService.create(token, character);
  }

  moveCentralMarker(activeUser: ActiveUser): void {
    this.characterMarkerService.move(activeUser);
  }

  panToCentralMarker(token: number) {
    this.characterMarkerService.panTo(token);
  }

  removeCentralMarker(token: number): void {
    this.characterMarkerService.remove(token);
  }

  setCommentToCentralMarker(message: CommentMessage) {
    this.characterMarkerService.setComment(message);
  }

  putCommentMarker(message: CommentMessage): void {
    if (this.strolyMap.hasLayer(this.commentMarker)) {
      this.strolyMap.removeLayer(this.commentMarker);
    }
    this.commentMarker = new PointMarker(
      { lat: message.latlng.lat, lng: message.latlng.lng },
      message.color,
    );
    this.commentMarker.addTo(this.strolyMap);
  }

  getCurrentLocation() {
    if (this.strolyMap && this.strolyMap.locate) {
      this.strolyMap.locate({
        watch: true,
        enableHighAccuracy: true,
      });
    }
  }

  stopGettingCurrentLocation() {
    this.strolyMap.stopLocate();
    this.strolyMap.fire('locationstop');
  }

  putLocationMarker(activeUser: ActiveUser) {
    if (this.locations[activeUser.token]) {
      this.locations[activeUser.token].setLatLng([
        activeUser.lat,
        activeUser.lng,
      ]);
      return;
    }

    this.locations[activeUser.token] = new LocationMarker(
      { lat: activeUser.lat, lng: activeUser.lng },
      new Character(activeUser.name, activeUser.userType),
    ).addTo(this.strolyMap);
  }

  getLocationMarkerLatLng(token: number) {
    return this.locations[token].getLatLng();
  }

  panToLocationMarker(token: number) {
    const locationMarker = this.locations[token];
    if (locationMarker) {
      this.strolyMap.panTo(locationMarker.getLatLng());
    }
  }

  setCommentToLocationMarker(message: CommentMessage) {
    const locationMarker = this.locations[message.token];
    if (locationMarker) {
      locationMarker.setIcon(
        LocationMarker.createIcon(
          new Character(message.name, message.userType),
          message.comment,
        ),
      );
    }
  }

  removeLocationMarker(token: number) {
    if (this.locations[token]) {
      this.strolyMap.removeLayer(this.locations[token]);
      delete this.locations[token];
    }
  }

  panTo(lat: number, lng: number) {
    this.strolyMap.panTo(new L.LatLng(lat, lng));
  }

  panToCenter() {
    this.panTo(this.centerLatLng.lat, this.centerLatLng.lng);
  }
}
