import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';

import { ActiveUser, ActiveUsers } from '../../../../domains/map';
import { User, UserType } from '../../../../domains/user/user.model';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  user: User;
  activeUsers: ActiveUsers;
  isUserListOpen: boolean;
  setIsUserListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCharacterClick: (token: number) => void;
};

const UserList = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    userList: {
      position: 'absolute',
      left: '16px',
      top: '80px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    userListButton: {
      boxSizing: 'border-box',
      paddingTop: '8px',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    top: {
      backgroundColor: '#fff',
      textAlign: 'center',
      borderRadius: '50% 50% 0 0',
      width: '48px',
      height: '24px',
      paddingTop: '8px',
    },
    bottom: {
      backgroundColor: '#fff',
      textAlign: 'center',
      borderRadius: '0 0 50% 50%',
      width: '48px',
      height: '24px',
      cursor: 'pointer',
    },
    disabled: {
      opacity: '0.2',
    },
    paper: {
      zIndex: 1001,
      maxHeight: 'calc(50vh - 32px - 48px - 32px - 24px)',
      height: '100%',
      width: '48px',
      boxSizing: 'border-box',
      textAlign: 'center',
      backgroundColor: 'rgba(240,240,240,0.9)',
      overflowY: 'scroll',
      borderRadius: 0,
    },
    ul: {
      listStyleType: 'none',
      margin: 0,
      padding: '0 0 8px 0',
    },
    li: {
      borderBottom: '4px solid #bdbdbd',
      paddingBottom: '8px',
    },
    icon: {
      display: 'inline-block',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      cursor: 'pointer',
      margin: '8px 8px 0',
      verticalAlign: 'middle',
    },
    guideIcon: {
      backgroundColor: '#fff',
      backgroundImage: `url('/images/characters/guide_black.svg')`,
      backgroundSize: 'auto 26px',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    browse: {
      cursor: 'standard',
      backgroundColor: '#929292',
      lineHeight: '32px',
      color: '#fff',
    },
    guide: {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#424242',
    },
  };

  const overrideStyles = makeStyles({
    tooltip: {
      color: '#333',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
  });

  const tooltipStyle = overrideStyles();

  const [isEnabled, setIsEnabled] = React.useState(true);
  function handleButtonClick() {
    if (isEnabled) {
      gtag('event', 'click', {
        event_category: 'button',
        event_label: props.isUserListOpen ? 'userlist close' : 'userlist open',
      });
      props.setIsUserListOpen(!props.isUserListOpen);
      setIsEnabled(false);

      setTimeout(() => {
        setIsEnabled(true);
      }, 2000);
    }
  }

  const list = Object.values(props.activeUsers)
    .sort((activeUser: ActiveUser) => {
      return activeUser.token === props.user.token ? -1 : 0;
    })
    .map((activeUser: ActiveUser) => {
      return (
        <li
          style={props.user.token === activeUser.token ? style.li : {}}
          key={activeUser.token}>
          <Tooltip
            title={activeUser.name}
            classes={{
              tooltip: tooltipStyle.tooltip,
            }}
            placement="right">
            {activeUser.userType === UserType.Guide ? (
              <div
                style={{ ...style.icon, ...style.guideIcon }}
                onClick={() =>
                  props.handleCharacterClick(activeUser.token)
                }></div>
            ) : (
              <img
                src={`/images/characters/${activeUser.name.substr(0, 1)}.svg`}
                alt="icon"
                width="32"
                height="32"
                style={style.icon}
                onClick={() => props.handleCharacterClick(activeUser.token)}
              />
            )}
          </Tooltip>
          {activeUser.userType === UserType.Guide ? (
            <div style={style.guide}>
              <FormattedMessage id="UserList.Guide" />
            </div>
          ) : null}
        </li>
      );
    });

  const browseModeUser =
    props.user.type === UserType.Browse ? (
      <li style={style.li}>
        <span style={{ ...style.icon, ...style.browse }}>No</span>
      </li>
    ) : null;

  return (
    <div style={style.userList}>
      {props.isUserListOpen ? (
        <>
          <div style={style.top}>
            <img
              src="/images/icons/user.svg"
              alt="user"
              width="24"
              height="16"
              style={style.disabled}
            />
          </div>
          <Paper style={style.paper}>
            <ul style={style.ul}>
              {browseModeUser}
              {list}
            </ul>
          </Paper>
          <div
            style={{
              ...style.bottom,
              cursor: isEnabled ? 'pointer' : 'not-allowed',
            }}
            onClick={handleButtonClick}>
            <img
              src="/images/icons/arrow_top_black.svg"
              alt="close"
              width="12"
              height="6"
              style={{
                opacity: isEnabled ? 1 : 0.2,
              }}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            ...style.userListButton,
            cursor: isEnabled ? 'pointer' : 'not-allowed',
          }}
          onClick={handleButtonClick}>
          <img
            src="/images/icons/user.svg"
            alt="user"
            width="24"
            height="16"
            style={{ opacity: isEnabled ? 1 : 0.2 }}
          />
          <br />
          <img
            src="/images/icons/arrow_bottom_black.svg"
            alt="open"
            width="12"
            height="6"
            style={{ verticalAlign: '3px', opacity: isEnabled ? 1 : 0.2 }}
          />
        </div>
      )}
    </div>
  );
});

export default UserList;
